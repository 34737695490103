import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {
    const start = 6;
    const end = 22;

    const app = useApp();
    let map = {
        [start]:{
            speed: 1,
        },
        [end]:{
            speed: 1,
            reach: 1,
        },
    };
    for(let i = start + 1; i < end; i++){
        map[i] = {
            speed: 0,
        }
    }
    const x = app.view.x(map);

    

    const url = `assets/spaceship.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        
        const shipSize = 15;
        //const transGap = 60 - shipSize;
        //寶石近來球修正
        
        const move = app.view.range(7, 8, 30).scale;
        const move2 = app.view.range(9.6, 10.1, 30).scale;
        const move3 = app.view.range(12, 13, 30).scale;
        //const move4 = app.view.range(20.6, 21, 26).scale;
        const rotate = app.view.range(18, 18.6, 180).scale;
        const trans = -move2+move3;
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                transform = {`translateY(${trans}vh)`}
                bottom = {`${50 - shipSize/2}vh`}
                zIndex={2}
            >
                <Image 
                    src = {url} 
                    transform = {`translateY(${x+(-30+move)}px) rotate(${-rotate}deg) scale(${1+rotate/300 + move3/60})`}
                    height = {`${shipSize}vh`}
                />
            </Box>
        )
    }
}