import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {

    const range = 55;
    const app = useApp();
    const x = app.view.x({
        0:{
            speed: 0,
        },
        1:{
            speed: 0,
        },
        2:{
            speed: 1,
            reach: 1,
        },
    });

    // 圖檔預載
    useEffect(() => {
        for(let i = 1; i <= range; i++){
            ImagePreloader.simplePreload(`assets/title_trans/titleTran_${i}.webp`);
        }
    }, []);

    if(x == null){
        return null;
    }else{
        const id = Math.ceil(app.view.range(0, 0.8, range).transform);
        return (
            <Box 
                // pointerEvents = {'fill'}
                position = {'absolute'} 
                transform = {{
                    base: 'scale(1.8)',
                    md: 'scale(2)',
                }}
                transitionDuration= '.5s'
                _hover = {{
                    // border: '5px solid yellow',
                    filter: 'drop-shadow(0px 0px 12px rgba(255, 243, 133, 0.75))',
                }}
            >
                <Image 
                    src = {`assets/title_trans/titleTran_${(id <= 1)? 1:id }.webp`} 
                    transform = {`translateY(${x}px)`}
                    width = {{
                        base: '100vw',
                        md: '65vw',
                        lg: '55vw',
                    }}
                />
            </Box>
        )
    }
}