import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";
import style from './style/GemCircle.module.scss'
import GemBar from "../view/GemBar";

export default () => {

    const app = useApp();
    const x = app.view.x({
        7:{
            speed: 0,
        },
        8:{
            speed: 0,
        },
        9:{
            speed: 0,
        },
        10:{
            speed: 0,
        },
    });


    // const url = `assets/gems_ring.png`;
    // useEffect(() => {
    //     ImagePreloader.simplePreload(url);
    // }, []);

    if(x == null){
        return null;
    }else{
        const move = app.view.range(7, 10, 200);
        const opacity = (move.scale > 72 && move.scale < 160)? 1:0;
        const scale = (move.scale > 72 && move.scale < 160)? 1:0.8;

        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
            >
                <Box
                    width={{
                        base: `80vw`,
                        md: `40vw`,
                    }}
                    height = {{
                        base: `80vw`,
                        md: `40vw`,
                    }}
                    transform = {`scale(${scale})`}
                    position={`relative`}
                    className={style.gemCircle}
                    opacity = {opacity}
                    transition={`transform 0.2s, opacity 0.2s`}
                >
                    {/* <Image 
                        position={`absolute`}
                        src = {url} 
                        width = {'100%'}
                        
                    /> */}
                    <Flex
                        position={`absolute`}
                        inset={`0`}
                        transform = {`rotate(${move.transform}deg)`}
                        justifyContent={`center`}
                        alignItems={`center`}
                    >
                        {[1,2,3,4,5,6].map((i) => (
                            <GemBar rotate = { move.transform } sort = {i} key = {i}/>
                        ))}
                    </Flex>
                </Box>
            </Box>
        )
    }
    
}