import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";

export default () => {

    const app = useApp();
    const x = app.view.x({
        21:{
            speed: 0,
        },
        22:{
            speed: -1,
            reach: 1,
        },
    });

   

    if(x == null){
        return null;
    }else{
        const sectionLength = 97;
        let parentProps = {
            transition: 'all 0.5s',
            transform: {base:`translateX(${app.state.planet * 30}vw)`,md:`translateX(${app.state.planet * 45}vw)`},
        };
    
        // if(app.state.planet == 0){
            parentProps.bottom = '4vh';
        // }else{
        //     parentProps.bottom = '50vh'; 
        // }
    
        return (
            <Box 
                position = {'absolute'} 
                transform={`translateY(${x}px)`}
                inset={'0'}
                zIndex={1} 
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Box 
                    pointerEvents = {'fill'}
                    position = {'absolute'} 
                    
                    display={'inline-flex'}
                    
                    {...parentProps}
                >
                    { (
                        app.state.planet == 0 || app.state.planet == -1
                    ) && (
                            <Box 
                                marginX={`10px`}
                                cursor={`pointer`}
                                onClick={() => {
                                    app.state.set((state) => {
                                        state.planet = 1;
                                        state.sectionLength = sectionLength;
                                    })
                                }}
                            >
                                <TextBox 
                                    bgColor = {`#57D773`}
                                    padding = {`0px`} 
                                    w = {`auto`} 
                                    stairs={3}
                                >←環境星球</TextBox>
                            </Box>
                        )
                    }

                    { (
                        app.state.planet == 0 || app.state.planet == 1
                    ) && (
                        <Box 
                            marginX={`10px`}
                            cursor={`pointer`}
                            onClick={() => {
                                app.state.set((state) => {
                                    state.planet = -1;
                                    state.sectionLength = sectionLength;
                                })
                            }}
                        >
                            <TextBox 
                                bgColor = {`#D2C22D`}
                                padding = {`0px`} 
                                w = {`auto`}
                                stairs={3}
                            >情緒星球→</TextBox>
                        </Box>
                        )
                    }
                    
                </Box>
            </Box>
        )
    }
}