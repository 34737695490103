import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {

    const app = useApp();
    const x = app.view.x({
        3:{
            speed: 1,
            reach: -1,
        },
        4:{
            speed: 0,
            reach: 0,
            proxy: (x) => x + app.state.windowHeight 
        },
        5:{
            speed: 1,
            reach: 0,
        },
        6:{
            speed: 1,
            reach: 1,
        },
        7:{
            speed: 1,
            reach: 2,
        },
    });

    const url = `assets/earth.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        const move = app.view.range(5, 5.7, 100).scale;
        //console.log(move);
        return (
            <Box 
                
                position = {'absolute'} 
                transform={`translateY(${-50+move}vh)`}
            >
                <Image 
                    src = {url} 
                    transform = {`translateY(${x}px)`}
                    height = {'280vh'} 
                    minWidth={'280vh'}
                />
            </Box>
        )
    }
    
}