import { Box, Input } from "@chakra-ui/react";
import { useEffect } from "react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";

export default () => {
    const app = useApp();
    const x = app.view.x({
        // 2:{
        //     speed: 1,
        // },
        3:{
            speed: 1,
        },
        4:{
            speed: 1,
            reach: 1,
        },
        // 4:{
        //     speed: 1,
        //     reach: 2,
        // }
    });


    useEffect(() => {
        if(app.state.userName.length >= 10 && app.state.nameFieldMsg == null){
            if(app.state.userName == 'impact2530'){ //impact2530
                app.state.set((state) => {
                    state.sectionLength = 43;
                    state.sectionSize = 1;
                    state.nameFieldMsg = {
                        status: true,
                        msg: '通行碼已確認 ! Hello 具有利他精神的您，​​歡迎您啟動硬派太空梭。',
                    }
                })
            }else{
                app.state.set((state) => {
                    state.userName = '';
                    state.nameFieldMsg = {
                        status: false,
                        msg: '通行碼錯誤 ! 請重新輸入',
                    }
                })
            }
        }
    }, [app.state.userName]);

    useEffect(() => {
        if(app.state.nameFieldMsg != null){
            if(!app.state.nameFieldMsg.status){
                setTimeout(() => {
                    app.state.set((state) => {
                        state.nameFieldMsg = null;
                    })
                }, 1500)
            }
        }
    }, [app.state.nameFieldMsg]);
  
    if(x == null){
        return null;
    }else{
        //const move = app.view.range(3, 4, 280).scale;
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                top = {'15vh'}
                //transform={`translateY(${move}vh)`}
                // onClick = {() => {
                //     app.state.set((state) => {
                //         state.sectionLength = 43;
                //         state.sectionSize = 1;
                //         state.nameFieldMsg = {
                //             status: true,
                //             msg: '通行碼已確認 ! Hello 具有利他精神的您，​​歡迎您啟動硬派太空梭。',
                //         }
                //     })
                // }}
            >
                <Box 
                    pointerEvents = {'fill'}
                    transform={`translateY(${x}px)`}
                >
                    <TextBox w='fill'>
                        {(app.state.nameFieldMsg == null) && (
                            <Box display={`inline-flex`} alignItems={'center'} justifyContent={'center'} >
                                <Box>請輸入太空梭編號：</Box>
                                <Box width={`100%`} flex={`1`} borderBottom={`1px solid #FFF`}>
                                    <Input 
                                        color={`#fff`} 
                                        padding={`0px 15px`} 
                                        width={`100%`} border={`0px`} 
                                        value = {app.state.userName} 
                                        //onKeyDown={handleKeyDown} 
                                        onChange={(e) => {
                                        app.state.set((state) => {
                                            state.userName = e.target.value;
                                        })
                                    }}/>
                                </Box>
                            </Box>
                        )}

                        {(app.state.nameFieldMsg != null) && (
                            <Box display={`inline-flex`} alignItems={'center'} justifyContent={'center'} >
                                <Box>{app.state.nameFieldMsg.msg}</Box>
                            </Box>
                        )}
                    </TextBox>
                </Box>
            </Box>
        )
    }
}