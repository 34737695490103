import { Box } from "@chakra-ui/react";

// 載入資料
//import Sections from "./data/Sections";
import Images from "./data/Images";

// 載入元件
import PrimaryLogo from "./items/PrimaryLogo";
import NameField from "./items/NameField";
import SubTitle from "./items/SubTitle";
import Earth from "./items/Earth";
import SpaceKid from "./items/SpaceKid";
import SpaceShip from "./items/SpaceShip";
import GemCircle from "./items/GemCircle";
import TextArea from "./items/TextArea";
import ImageBox from "./items/ImageBox";
import TitleBox from "./items/TitleBox";
import PlanetSelect from "./items/PlanetSelect";
import PlanetSelectBtn from "./items/PlanetSelectBtn";
import ShipReturn from "./items/ShipReturn";
import Cube from "./items/Cube";
import GalaxyBG from "./items/GalaxyBG";
import Schedule from "./items/Schedule";
import QA from "./items/QA";
import Advisor from "./items/Advisor";
import Team from "./items/Team";
import Sponsor from "./items/Sponsor";
import LandingInfo from "./items/LandingInfo";
import Flag from "./items/Flag";
import FloatSpaceMan from "./items/FloatSpaceMan";


export default () => {
    return (
        <Box
            pointerEvents={'none'}
            position={'absolute'}
            inset={'0px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            // zIndex={'100'}
        >
            <GalaxyBG />
            {/* 開頭動畫 */}
            <PrimaryLogo/>

            {/* 引言 */}
            <SubTitle />

            {/* 名字輸入框 */}
            <NameField />

            {/* 地球 */}
            <Earth />

            {/* 太空小朋朋 */}
            <SpaceKid />

            {/* 寶石環 */}
            <GemCircle />

            {/* 文字區塊 */}
            <TextArea />

            {/* 段落標題 */}
            {/* {Sections.map((item, i) => (
                <SectionTitle
                    key = {i}
                    item = {item}
                />
            ))} */}

            <TitleBox />
            <PlanetSelect />
            <PlanetSelectBtn />
            
            {/* 圖片區塊 */}
            {Images.map((item, i) => (
                <ImageBox
                    key = {i}
                    item = {item}
                />
            ))}
            
            {/* 火箭 */}
            <SpaceShip />
            <ShipReturn />
            <Cube />
            <Schedule />
            <QA /> 
            <Advisor />
            <Team />
            <Sponsor />
            <LandingInfo />
            <Flag />
            <FloatSpaceMan />
        </Box>
    )
}