import { Flex, Box } from '@chakra-ui/react';

export default ({lable, bg, children}) => {
    return (
        <Flex
            flexDirection={'row'}
            // transform={{base:'scale(50%)', md:'scale(100%)'}}
            mb='10px'
        >
            <Box 
                flex={1}
                color={'#fff'}
            >
                {children}
            </Box>
            <Box flexBasis={'content'}>
                <Box 
                    width={`30px`} 
                    height={`100%`}
                    backgroundColor={bg} 
                    border={`2px solid #fff`} 
                    flexDirection={'column'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    {lable.split("").map((item, idx) => (
                        <Box key={idx}>{item}</Box>
                    ))}
                </Box>
            </Box>
        </Flex>
    )
}