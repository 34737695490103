// //import { AddToCalendarButton } from 'add-to-calendar-button-react';
// import { google, outlook, office365, yahoo, ics } from "calendar-link";
import cal from 'generate-calendar-url';

export default [
    {
        startAt: 8,
        title: {
            text: null,
            sub: null,
        },
        dialogue:{
            text: '酷童實驗所招募 12 個家族前往其他星球\n找尋能讓地球永續的祕密！'
        }
    },
    {
        startAt: 10,
        title: {
            // text: '永續傳承　　　從家經營',
            sub: '利他精神　　　從心開始',
        },
        dialogue:{
            text: `重視利他精神的您，​可透過此計劃讓孩子擁抱情緒教育，​​學習理解自己、尊重他人、關懷社群；
            ​​與我們一起將利己利他的信念融入家庭教育中，​讓孩子成為改變世界的影響力接班人。`
        }
    },
    {
        startAt: 12,
        title: {
            text: null,
            sub: null,
        },
        dialogue:{
            text: `酷童實驗所由三大元素所組成：
            硬派接班人、硬派俱樂部、酷童出任務
            支持您的孩子透過認識情緒建立利他精神，​​陪伴您與家人結識有慈善精神的家族，​​贊助偏遠地區孩子體驗永續教育。\n
            ​​＊基金會全額贊助參與孩子的費用，參與活動家庭不需支付基金會任何費用`
        }
    },
    {
        startAt: 14,
        title: {
            text: '[​​硬派接班人]',
            sub: '孩子的星際冒險-情緒智能提升計畫\n硬派座談-親子座談會',
        },
        dialogue:{
            text: `邀請父母與孩子一起正式登上硬派太空梭，​在遊戲的過程享受親子共創​與其他家庭交流家庭教育的經驗談`,
            detail: `日期：2023 年 9 月 24 日
            ​​時間：下午 13:00-18:00（完成報名後會收到具體資訊）
            ​​地點：北北桃地區（完成報名後會收到具體資訊）
            ​​師資：好好星球基金會團隊、學次方創意學習協會、PNG思維行動
            ​​與會貴賓：依時段與其中一位交流
            ​​施惠文 - 為台灣而教基金會執行長
            ​​徐聖惠 - 臺北市私立靜心高中附設國小部副校長`
            ,
        },
    },
    {
        startAt: 16,
        title: {
            text: '[硬派俱樂部] ',
            sub: 'ESG 永續精神共學計畫\n好星名人下午茶敘',
        },
        dialogue:{
            text: '邀請研華股份有限公司劉克振董事長\n與參與計畫的貴賓家族成員\n分享家族基金會的實務運作故事\n聆聽各個家族期待永續傳承的家族精神',
            detail: `日期：2023 年 10 月 28 日（六）
            ​​時間：下午 13:30-16:30
            ​​地點：北北桃地區（完成報名後會收到具體資訊）
            ​​歡迎邀請更多有興趣的夥伴參與茶敘，一起討論、學習慈善公益概念！
            `
        },
        
    },
    {
        startAt: 18,
        title: {
            text: '[硬派接班人]',
            sub: '好星太空船 - 啟程儀式',
        },
        dialogue:{
            text: '運用遊戲破冰，讓孩子融入全新的團體\n透過實境解謎遊戲，讓孩子邊玩邊認識團隊合作\n陪伴孩子擬定自己在計畫中要實踐的小目標',
            detail: `日期：2023 年 10 月 28 日（六）
            ​​時間：下午 13:00-17:00
            ​​地點：北北桃地區（完成報名後會收到具體資訊）
            ​​師資：好好星球基金會團隊
            `
        }
    },
    // {
    //     startAt: 20,
    //     title: {
    //         text: null,
    //         sub: null,
    //     },
    //     dialogue:{
    //         text: '太空梭即將降落\n請選擇降落地點',
    //     }
    // },
    {
        startAt: 24,
        title: {
            text: '[返航慶功宴]',
            sub: '成果發表暨酷童出任務啟動儀式',
        },
        dialogue:{
            text: '濃縮七個月的成長精華，此場活動由孩子們主導；邀請您親自參與孩子們的改變行動，共同見證孩子們創造的影響力\n同時邀請您與孩子一起 Kick Off 酷童出任務',
            detail: `時間： 2024 年 04 月 14 日（日）11:00-17:00
            地點：北北桃地區（完成報名後會收到具體資訊）
            `
        }
    },
    {
        startAt: 26,
        title: {
            text: '[酷童出任務]',
            sub: '利己利他公益教育營隊捐贈計畫',
        },
        dialogue:{
            text: `酷童實驗所希望讓每位孩子都能擁有影響力，​於是決定將資金、資源導入非偏鄉，​​但離市區較遠的「不山不市」學校。`,
            detail: `講師：、學次方、禮德旭 Kids Leaducation、社團法人籿苗教育發展協會、心靈角落工作室
            主題：情緒教育、社會議題、問題解決等
            時間：2024 年 4 月啟動
            地點：新北市偏遠地區學校（如：石碇區、平溪區等）
            皆可取得節稅用之捐贈收據
            `
        }
    },
    {
        startAt: 28,
        title: {
            text: '[酷童出任務]',
            sub: '10 萬元公益金 ＝ 讓 1 間偏遠學校\n一整個年級的學生參與一場永續利他營',
        },
        dialogue:{
            text: `支持最缺乏資源與關注的孩子，​​體驗最有趣的影響力啟蒙活動；
            ​​若您也認同我們的理念，邀請您一起盡一份心力`,
        },
    },
    {
        startAt: 30,
        title: {
            text: '[入選權利]',
            sub: null,
        },
        dialogue:{
            text: '凡是完整繳交報名資料者，\n孩童與家族即可入選硬派接班人第一期計畫，\n獲得實體活動參與資格。\n',
            btn: {
                label: '加入日曆',
                func: () => {
                    // var event = {
                    //     title: 'BDay Bash',
                    //     start: new Date('July 20, 2020 19:00'),
                    //     end: new Date('July 20, 2020 22:00'),
                    //     location: '123 Fake St',
                    //     description: 'Johnny is having a blowout'
                    // };
                    // window.open(cal.google(event));
                    window.open('https://calendar.google.com/calendar/u/1?cid=Y184NjU4YjZkZWE1NzkyOWE2ZjA5ZDhlMjAwNjA3ZjQyZWI5MzllNjUyMGM0YWUwNGQxMTc2NzYwZTVlMzdhMzVkQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20')
                },
            }
        },
    },
    {
        startAt: 34,
        title: {
            text: '[預約 1on1 諮詢]',
            sub: null,
        },
        dialogue:{
            text: "對計畫有些疑問？不確定師資教學風格？​想更認識酷童實驗所的工作人員？​在此邀請您與孩子試登上太空船，與我們會面，​​我們將誠摯地為您解答所有疑難雜症。\n​​日期：即日起至 2023 / 09 /15（五）前\n​​地點：您方便的任何地點，或線上諮詢\n​​預約方式：加入硬派接班人官方 ```Line@：@childgoodlab``` 會由有專人與您聯繫或直接打電話至基金會電話：0972-872-478 預約",
            btn: {
                label: '立即預約諮詢',
                func: () => {
                    window.open('http://line.me/R/ti/p/@childgoodlab');
                }
            }
        },
    },
    {
        startAt: 36,
        title: {
            text: '[入選資格]',
            sub: null,
        },
        dialogue:{
            text: '孩童年紀須介於 112 學年度 4 年級至 112 學年度 7 年級，並於 2023 年 9 月 17 日（日） 23:59 前，完整繳交報名資料',
            btn: {
                label: '立即報名',
                func: () => {
                    window.open('https://www.surveycake.com/s/0Y21y', '_blank');
                }
            }
        },
    },
    // {
    //     startAt: 38,
    //     title: {
    //         text: '[常見ＱＡ]',
    //         sub: null,
    //     },
    //     dialogue:{
    //         text: ''
    //     },
    // },
    {
        startAt: 40,
        title: {
            text: '[聯繫方式]',
            sub: '如果還有其他問題想問？\n 該如何聯繫到你們？',
        },
        dialogue:{
            nlToBr: true,
            text: '加入硬派接班人官方 ```Line@：@childgoodlab``` \n會有專人與您聯繫\n或直接打電話至基金會電話：0972-872-478\n上班時間：週一到週五 10:00-17:00\n辦公室：台北市重慶南路一段121號4樓-14',
            btn: {
                label: '馬上加入官方Line',
                func: () => {
                    window.open('http://line.me/R/ti/p/@childgoodlab');
                },
            }
        },
    },
    {
        startAt: 42,
        title: {
            text: '[主辦單位]',
            sub: null,
        },
        dialogue:{
            text: '酷童實驗所願有一天台灣的所有孩童都能接觸利他生命教育，在成長環境中，建立服務精神。以實體活動培養孩童「人際、內省、感同身受」的能力，並提升其自我效能感，建立利於發生利他行動的環境。'
        },
    },
    // {
    //     startAt: 20,
    //     dialogue:{
    //         text: ''
    //     },
    //     title: {
    //         text: null,
    //         sub: null,
    //     }
    // },
];
