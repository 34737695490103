import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {

    const app = useApp();
    const x = app.view.x({
        3:{
            speed: 0.6,
            reach: 0,
        },
        4:{
            speed: -0.6,
            reach: 1,
        },

    });

    const url = `assets/space_kid.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        const move = app.view.range(4, 5, 200).scale;
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                inset={'0'}
                display={'flex'}
                justifyContent={'center'}
            >
                <Box 
                    width={'max-content'}
                    position = {'absolute'} 
                    bottom={'-5px'}
                    opacity={`${1 - (move/100)}`}
                >
                    <Image 
                        src = {url} 
                        transform = {`translateY(${x}px)`}
                        height = {{
                            base: '30vh',
                            md: '50vh',
                        }}
                    />
                </Box>
            </Box>
        )
    }
}