export default [
    {
        startAt: 10,
        speed: 0.6,
        image: 'assets/hand1.webp',
        parent: {
            top: '0px',
            left: '0px',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '60vw',
                md: '35vw',
            },
            transform: {
                base: 'translateX(-15vw) rotate(25deg)',
                md: 'rotate(0deg)',
            },
        }
    },
    {
        startAt: 10,
        speed: 1.3,
        image: 'assets/hand2.webp',
        parent: {
            bottom: '0px',
            right: '0px',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '75vw',
                md: '35vw',
            },
            transform: {
                base: 'translateX(15vw) rotate(25deg)',
                md: 'rotate(0deg)',
            },
        }
    },
    {
        startAt: 12,
        speed: 1,
        image: 'assets/planet_1.webp',
        delay: 2,
        parent: {
            top: '5vh',
            left: {
                base: '8vw',
                md: '26vw',
            },
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '37vw',
                md: '15vw',
            },
        }
    },
    {
        startAt: 12,
        speed: 1,
        image: 'assets/planet_2.webp',
        delay: 4,
        parent: {
            bottom: '10vh',
            right: {
                base: '-12vw',
                md: '8vw',
            },
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '48vw',
                md: '24vw',
            },
        }
    },
    {
        startAt: 14,
        speed: 1,
        image: 'assets/planet_3.webp',
        delay: 3,
        parent: {
            top: {
                base: '-30vh',
                md: '0vh',
            },
            left: {
                base: '-20vw',
                md: '5vw',
            },
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '58vw',
                md: '28vw',
            },
        }
    },
    {
        startAt: 14,
        speed: 1,
        image: 'assets/planet_4.webp',
        delay: 4,
        parent: {
            bottom: {
                base: '-30vw',
                md: '-0vh',
            },
            right: {
                base: '-30vw',
                md: '-5vw',
            },
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '65vw',
                md: '36vw',
            },
        }
    },
    {
        startAt: 16,
        speed: 1,
        image: 'assets/planet_5.webp',
        delay: 3,
        parent: {
            left: {
                base: '-50vw',
                md: '-12vw',
            },
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: {
                base: '80vw',
                md: '42vw',
            },
        }
    },
    {
        startAt: 18,
        speed: 1,
        image: 'assets/planet_6.webp',
        delay: 3,
        parent: {
            right: '-5vw',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: '32vw',
        }
    },
    // {
    //     startAt: 20,
    //     speed: 1,
    //     image: 'assets/bg_transfer.svg',
    //     parent: {
    //         bottom: '-101vh',
    //         right: '0px',
    //         left: '0px',
    //         height: 'fit-content',
    //     },
    //     child: {
    //         width: '100vw',
    //     }
    // },
    // {
    //     startAt: 21,
    //     speed: 1,
    //     image: 'assets/colorful_soil_with_flag.webp',
    //     parent: {
    //         top: '80vh',
    //         right: '0px',
    //         left: '0px',
    //         height: 'fit-content',
    //         display: 'flex',
    //         justifyContent: 'center',
    //         zIndex: 0,
    //     },
    //     child: {
    //         width: '70vw',
    //     }
    // },
    {
        startAt: 26,
        speed: 1,
        delay: 3,
        image: 'assets/planet_7.png',
        parent: {
            top: '10vh',
            left: '0px',
            height: 'fit-content',
            zIndex: 0,
        },
        child: {
            width: '30vw',
        }
    },
    {
        startAt: 28,
        speed: 1,
        delay: 4,
        image: 'assets/planet_5.webp',
        parent: {
            right: '-5vw',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: '32vw',
        }
    },
    {
        startAt: 30,
        speed: 1,
        delay: 5,
        image: 'assets/planet_4.webp',
        parent: {
            top: '10vh',
            left: '-8vw',
            height: 'fit-content',
            zIndex: 0,
        },
        child: {
            width: '28vw',
        }
    },
    {
        startAt: 32,
        speed: 1,
        delay: 4,
        image: 'assets/planet_1.webp',
        parent: {
            right: '-30vw',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: '70vw',
        }
    },
    {
        startAt: 34,
        speed: 1,
        delay: 3,
        image: 'assets/planet_2.webp',
        parent: {
            top: '10vh',
            left: '-20vw',
            height: 'fit-content',
            zIndex: 0,
        },
        child: {
            width: '60vw',
        }
    },
    // {
    //     startAt: 34,
    //     speed: .5,
    //     image: 'assets/float_spaceman.webp',
    //     parent: {
    //         right: '20vw',
    //         width: '10vw',
    //     }
    //     // child: {
    //     //     width: '36vw',
    //     // }
    // },
    {
        startAt: 35,
        speed: 1,
        image: 'assets/planet_3.webp',
        parent: {
            right: '-20vw',
            width: 'fit-content',
            height: 'fit-content',
        },
        child: {
            width: '36vw',
        }
    },
    {
        startAt: 36,
        speed: .5,
        image: 'assets/planet_3.webp',
        parent: {
            right : '-20vw',
            width: '50vw',
        }
        // child: {
        //     width: '36vw',
        // }
    },
    {
        startAt: 36,
        speed: .7,
        image: 'assets/stars.webp',
        parent: {
            left: '35vw',
            width: '30vw',
            // width={{
            //     base: '70vw',
            //     md: '20vw',
            // }},
        },
        // child: {
        //     width: '36vw',
        // }
    },
    {
        startAt: 40,
        speed: 1.2,
        image: 'assets/monster_connect.webp',
        parent: {
            left: '40vw',
            width: '20vw',
            // height: 'fit-content',
        },
        child: {
            width: '100%',
        }
    },
    {
        startAt: 41,
        speed: .5,
        image: 'assets/planet_7.webp',
        parent: {
            right: '18vw',
            width: '25vw',
            // height: 'fit-content',
        },
        child: {
            width: '100%',
        }
    },
    {
        startAt: 42,
        speed: 1,
        image: 'assets/title_childgood.webp',
        parent: {
            // left: '40vw',
            // width: '20vw',
            matgin: 'auto'
            // height: 'fit-content',
        },
        child: {
            width: '250px',
        }
    },
    {
        startAt: 43,
        speed: 1,
        image: 'assets/planet_1.webp',
        parent: {
            left: '5vw',
            width: '55vw',
            // height: 'fit-content',
        },
        child: {
            width: '100%',
        }
    },
    {
        startAt: 44,
        speed: 1,
        image: 'assets/planet_4.webp',
        parent: {
            right: '-5vw',
            width: '30vw',
            // height: 'fit-content',
        },
        child: {
            width: '100%',
        }
    },
]