import { Box } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";
import sections from "../data/Sections";

export default () => {

    const app = useApp();
    let title = {}

    //將段落對話加入
    sections.map((d) => {
        title[d.startAt] = {...d.title, type: 'in'};
        title[d.startAt+1] = {...d.title, type: 'out'};
    });


    if(title[app.view.section] == undefined){
        return null
    }else{
        const data = title[app.view.section];
        let move, text, sub = null, subArr = [];

        const textLength = data.text != null ? data.text.length : 0;
        const subLength = data.sub != null ? data.sub.length : 0;
        const fullLength = textLength + subLength;
        if(data.text != null){
            if(data.type == 'in'){
                move = app.view.range(app.view.section -1, app.view.section-0.3, fullLength);
                text = data.text.slice(0, Math.ceil(move.transform));
            }else{
                move = app.view.range(app.view.section, app.view.section+0.7, fullLength);
                text = data.text.slice(0, Math.floor(move.transform)*-1);
            }
        }

        if(data.sub != null){
            
            if(data.type == 'in'){
                move = app.view.range(app.view.section -1, app.view.section-0.3, fullLength);
                let  length = Math.ceil(move.transform) - textLength;
                if (length < 0) length = 0;
                sub = data.sub.slice(0, length);
            }else{
                move = app.view.range(app.view.section, app.view.section+0.7, fullLength);
                let  length = (Math.floor(move.transform)*-1) - textLength;
                if (length < 0) length = 0;
                sub = data.sub.slice(0, length);
            }
            subArr = sub.split("\n");
        }
        
        
        //console.log(app.view.section, text, move);
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                top = {'12vh'} 
                width={'100%'} 
                display={'flex'}
                alignItems={'center'} 
                flexDirection={'column'}
                zIndex={'10000'}
            >
                
                {text != null && (
                    <Box
                        position={`relative`}
                        textAlign={`center`}
                        fontSize={{base:`34px`, lg:`42px`}} 
                        color={'rgba(255,255,255,0)'} 
                        padding={`0px 0px 10px 0px`}
                    >
                        {data.text}
                        <Box
                            position={`absolute`}
                            inset={`0`} 
                            padding={`0px 0px 20px 0px`} 
                            color={'#fff'} 
                            fontSize={{base:`34px`, lg:`42px`}} 
                            textAlign={`left`}
                        >
                            {text}
                        </Box>
                    </Box>
                )} 
                {sub != null && (
                    <Box
                        textAlign={`center`}
                        width={'auto'}
                        fontSize={{base:`18px`, lg:`26px`}} 
                        color={'#fff'} 
                        display={'flex'}
                        alignItems={'center'} 
                        flexDirection={'column'}
                        lineHeight={'1'}
                    >
                        {data.sub.split("\n").map(function(item, idx) {
                            return (
                                <Box 
                                    width={'fit-content'}
                                    padding={`7px 0px`} 
                                    position={`relative`}
                                    key={idx}
                                    color={'rgba(255,255,255,0)'} 
                                >
                                    {item}
                                    {subArr[idx] != undefined && (
                                        <Box
                                            position={`absolute`}
                                            textAlign={`left`} 
                                            top={`0`}
                                            color={'#fff'}
                                        >
                                            {subArr[idx]}
                                        </Box>
                                    )}
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>
        )
    }
}