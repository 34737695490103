import { Box, Flex, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";


export default () => {

    const app = useApp();
    const x = app.view.x({
        48:{
            speed: 0.6,
        },
        49:{
            speed: 0.6,
            reach: 1,
        },
   },);



    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
            width={{
                base: `100vw`,
                // md: '70vw',
                // lg: '60vw',
            }}
            height={'fit-content'} 
            padding={{
                base: `0px 2vw`,
                md: '0px 10vw',
            }}
            transform={`translateY(${x}px)`}
        >
            <Box
                marginBottom={`5vh`}
                position={`relative`}
                textAlign={`center`}
                fontSize={`3rem`} 
                color={`#fff`}
            >[贊助單位]</Box>

            <Flex
                width={`100%`}
                flexDirection={{
                    base: `column`,
                    md: `column`,
                }}
                marginTop={`10vh`}
            >
                <Box fontSize={`1.6rem`} color={`#fff`} textAlign={`center`}>主要贊助</Box>
                <Flex 
                    width={`100%`}
                    flexWrap={`wrap`}
                    justifyContent={`center`}
                >
                    <Flex 
                        margin={`auto`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                    >
                        <Image width={{base: `180px`, lg:'180px'}} src={`assets/sponser_1.webp`}/>
                    </Flex>
                </Flex>

            </Flex>
            <Flex
                width={`100%`}
                flexDirection={{
                    base: `column`,
                    md: `column`,
                }}
                marginTop={`10vh`}
            >
                <Box fontSize={`1.6rem`} color={`#fff`} textAlign={`center`}>利他贊助</Box>
                <Flex 
                    width={`100%`}
                    flexWrap={`wrap`}
                    justifyContent={`center`}
                >
                    <Flex 
                        margin={`auto`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                    >
                        <Image width={{base: `120px`, lg:'160px'}} src={`assets/sponser_2.webp`}/>
                    </Flex>
                    <Flex 
                        margin={`auto`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                    >
                        <Image width={{base: `120px`, lg:'150px'}} src={`assets/sponser_3.webp`}/>
                    </Flex>
                </Flex>

            </Flex>
        </Box>
    )
}