
import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../App";
import { useState } from "react";

export default ({  }) => {
    const app = useApp();
    const [active, setActive] = useState(false);
    let map = [
        // {
        //     label: '大人的任務',
        //     sction: 14,
        // },
        // {
        //     label: '🔭硬派座談',
        //     sction: 14,
        // },
        // {
        //     label: '🛰好星名人下午茶敘',
        //     sction: 16,
        // },
        // {
        //     label: '孩童星際探索',
        //     sction: 18,
        // },
        // {
        //     label: '🚀好星太空船',
        //     sction: 20,
        // },
        // {
        //     label: '🪐降落星球',
        //     sction: 21,
        // },
        // {
        //     label: '🌏返航慶功宴',
        //     sction: 24,
        // },
        {
            label: '活動介紹',
            sction: 14,
        },
        {
            label: '​​酷童出任務',
            sction: 26,
        },
        {
            label: '入選權利',
            sction: 30,
        },
        {
            label: '好星時程',
            sction: 32,
        },
        {
            label: '預約 1on1 諮詢',
            sction: 34,
        },
        {
            label: '入選資格',
            sction: 36,
        },
        {
            label: '常見QA',
            sction: 38,
        },
        // {
        //     label: '聯繫方式',
        //     sction: 40,
        // },
        {
            label: '我們是誰',
            sction: 42,
        },
        // {
        //     label: '顧問名單',
        //     sction: 44,
        // },
        // {
        //     label: '團隊介紹',
        //     sction: 46,
        // },
        // {
        //     label: '贊助單位',
        //     sction: 48,
        // }
    ]
    //  用來鎖住還沒啟動的Section
    //map = map.filter((item) => (item.sction*2 <= app.state.sectionLength));
    
    return (
        <Box 
            pointerEvents={'none'}
            position={'fixed'}
            width={`100vw`}
            height={`100vh`}
            top={0}
            left={0}
            zIndex={'100'}
        >
            {(app.state.nameFieldMsg != null && app.state.nameFieldMsg.status != 0) && (
                <Box
                    marginTop={'10px'}
                    width={`60px`} 
                    // height={`60px`}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => {
                        window.open('https://www.surveycake.com/s/0Y21y', '_blank');
                    }}

                >
                    <Image
                        cursor={'pointer'}
                        pointerEvents={'fill'}
                        src={`assets/menu_1.webp`} 
                        width={`38px`} 
                        height={`auto`} 
                    />
                    <Box color='white' fontSize='10px'>立刻報名</Box>
                </Box>
            )}
            {(app.state.nameFieldMsg != null && app.state.nameFieldMsg.status != 0) && (
                <Box
                    width={`60px`} 
                    height={`60px`}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => {
                        setActive(!active);
                    }}
                >
                    <Image
                        cursor={'pointer'}
                        pointerEvents={'fill'}
                        src={`assets/menu_2.webp`} 
                        width={`38px`} 
                        height={`auto`} 
                    />
                </Box>
            )}
            {(active) && (
                <Box
                    
                    position={'absolute'}
                    width={`fit-content`}
                    height={`fit-content`}
                    top={`10px`}
                    left={`70px`}
                >
                    {map.map((item, i) => (
                        <Box
                            key={i}
                            color={'#FFF'}
                            pointerEvents={'fill'}
                            cursor={'pointer'}
                            marginTop ={`5px`}
                            padding={`2px`}
                            background={`rgba(0,0,0,1)`}
                            width={`fit-content`}
                            onClick={() => {
                                
                                app.state.set((e) => {
                                    e.targetTop = item.sction * app.state.windowHeight;
                                    e.sectionLength = 97;
                                })
                            }}
                        >
                            {item.label}
                        </Box>
                    ))}

                </Box>
            )}

        </Box>
    )
}