import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default ({ item }) => {
    
    const app = useApp();
    let map = {};

    if(item.delay == undefined) item.delay = 1;
    for(let i = 0; i < item.delay; i++){
        map[item.startAt-i] = {
            speed: item.speed,
            reach: -i,
        }
        map[item.startAt+i+1] = {
            speed: item.speed,
            reach: i+1,
        }
    }
    const x = app.view.x(map);


    useEffect(() => {
        ImagePreloader.simplePreload(item.image);
    }, []);

    if(x == null){
        return null;
    }else{
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                transform = {`translateY(${x/item.delay}px)`}
                {...item.parent}
            >
                <Image 
                    src = {item.image} 
                    {...item.child}
                />
            </Box>
        )
    }
    
}