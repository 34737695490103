import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";
import { motion } from "framer-motion";


export default ({ item }) => {
    
    const app = useApp();
    const x = app.view.x({
        34:{
            speed: 0.5,
            reach: 0,
        },
        35:{
            speed: 0.5,
            reach: 1,
        },
    });


    const url = 'assets/float_spaceman.webp';
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        const move = app.view.range(34, 35, 50).transform;
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                transform = {`translateY(${x}px)`}
                right = {'20vw'}
                width = {'15vw'}
            >
                <motion.div
                    style={{
                        width: '100%',
                    }}
                    animate={{
                    x: [-5, 5, 5, -5],
                    y: [-5, -5, 5, -5],
                        transition: {
                            repeat: Infinity,
                            duration: 5,
                            damping: 1.9
                        }
                    }}
                >
                    <Image 
                        transform = {`rotate(${move-20}deg)`}
                        src = {url} 
                        width = {'100%'}
                    />
                </motion.div>
            </Box>
        )
    }
    
}