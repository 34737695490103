import { Box, Flex, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";
import { useState } from "react";

export default () => {

    const [activeID, setActiveID] = useState(1);

    const index = {
        1: {
            content: (
                <Box>
                    <Box>Soul LAB 的願景是希望能培養出一個在未來能發揮利他精神影響力的女性社群，透過溫和而堅定的力量，實際發起以他人福利為宗旨的利他行動，進而對社會產生良善的正向能量。透過「感恩、復原、同理、覺察、行動」，持續提升學員的利他精神，並支持已有利他行動經驗的女性，建立「將利他融入長期生涯職涯發展」。
                    </Box><br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://wellplanet.org.tw/" target="_blank">Soul LAB</a>
                </Box>
            )
        },
        2: {
            content: (
                <Box>
                    <Box>以戶外企劃設計並精準執行代表著思維行動所有產出的重要基礎核心。擅長將自然體驗與生活經驗做串連，以大自然為場域，體驗設計為方法，拉近人與他人及自然三者間的距離，透過創造美好的生活體驗，啟發人對生活的更多想像，在安全風險控管下打造都市人與自然能夠更親近連結的行動方案，期待一個共生共榮的未來。</Box><br/>
                    特殊專案成就：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://www.haofun.tw/view/article/agrifood/tour/" target="_blank">ESG 永續旅展受邀參展</a>、<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://taipeiwalker.walkerland.com.tw/articles/view/2675" target="_blank">TaipeiWalker 雜誌專訪</a><br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://planandgo.tw/" target="_blank">思維行動 Plan and Go</a>
                </Box>
            )
        },
        3: {
            content: (
                <Box>
                    <Box>來自新北三峽的非學校型態實驗教育團體，學次方堅信「自主思辨」是學習的重要核心，「認真遊戲，快樂學習」則是貫徹學次方的實踐理念。當孩子有機會自己做選擇、有機會實際去應用所學時，對學習的渴求之心便油然而生。因此，我們盡力做到讓孩子能從自身的好奇心出發，一面探索一面學習需要的工具與方法，真正達到自主學習。</Box><br/>
            特殊專案成就：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://wabay.tw/projects/funbeida-streetplay?locale=zh-TW" target="_blank">三峽瘋北大兒童封街活動主辦</a>、入選 2023 親子天下教育創新100<br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://learn4power.com/" target="_blank">學次方Learn4power</a>
                </Box>
            )
        },
        4: {
            content: (
                <Box>
                    <Box>心靈角落是由兩位熱愛桌遊的諮商心理師組成，不小心太愛玩桌遊就開始設計與出版桌遊，我們擅長用說故事，所以將我們擅長的方式包裝桌遊，用有趣好玩的方式和大家討論心理與社會議題，也期待藉著大家的手，共構一個更溫暖及溫柔的社會。<br/>
                    心靈角落有一個希望和相信。希望有一天，生命之間能互相理解、尊重；相信有一天，每個人都能好好被對待。</Box><br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://www.facebook.com/PsychoasisStudio/" target="_blank">心靈角落</a>
                </Box>
            )
        },
        5: {
            content: (
                <Box>
                    <Box>直覺職掘成立 5 年以來受到葉丙成教授、藍偉瑩老師、米蘭老師等教育前輩的支持與推廣。透過「營隊」及「課程」的形式，匯聚了來自多元領域的職人講師、熱血投身教育的青年。
                    團隊也在陪伴國中生的過程中，建構出一套「體驗教育」及「引導反思」的系統。</Box><br/>
                    特殊專案成就：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://vocationworkshop.wixsite.com/familychallenge" target="_blank">主辦親子挑戰賽</a><br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://vwsicamp.com/" target="_blank">直覺職掘</a>
                </Box>
            )
        },
        6: {
            content: (
                <Box>
                    <Box>「社團法人籿苗教育發展協會」是由小人小學創辦人 - 扣扣老師所發起的公益協會。「建立一套可複製化移轉的偏鄉教育模組」一直是籿苗教育發展協會在做的事，培力偏鄉孩子、老師、青年，用創新教育注入鄉村打造地方教育培力網絡。</Box><br/>
                    特殊專案成就：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://tw100-2021.cwgv.org.tw/contents/results_demo?id=636" target="_blank">GFF全國女孩聯盟</a>、<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://artouch.com/artco-kids/content-2208041.html" target="_blank">ArtBus藝術車</a><br/>
                    粉絲專頁：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://www.facebook.com/Kiddorun.01/?locale=zh_TW" target="_blank">小人1號：南澳小孩的圖書遊樂場</a>
                </Box>
            )
        },
        7: {
            content: (
                <Box>
                    <Box>禮德旭 Kids Leaducation（社團法人台灣兒童領導力教育協會）的願景是「所有孩子都能與他人共創理想教育，打造共好社會。」透過與孩子成為夥伴，激發其內在領導力，一起打造更理想的教育、社會。從 2023 年開始開辦明日學校營隊，與不同議題組織合作共創，激發孩子的 4C 領導力（自我覺察、溝通合作、創意設計、擁抱挑戰），並透過每月線上講座支持家長與教育工作者具備「與孩子成為夥伴」的心法與技巧。</Box><br/>
                    特殊專案成就：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://pse.is/4hdrba" target="_blank">2022 年孩是夥伴線上教育展</a>、<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://si.taiwan.gov.tw/Home/News?Fid=245" target="_blank">112 年社創中心進駐團隊</a><br/>
                    官方網站：<a style={{pointerEvents: 'fill', cursor: 'pointer', textDecoration: 'underline'}} href="https://kidsleaducation.org/" target="_blank">禮德旭</a>
                </Box>
            )
        },
    }

    const app = useApp();
    const x = app.view.x({
        46:{
            speed: 0.6,
        },
        47:{
            speed: 0.6,
            reach: 1,
       },
   },);



    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
            width={{
                base: `100vw`,
                // md: '70vw',
                // lg: '60vw',
            }}
            height={'fit-content'} 
            padding={{
                base: `0px 2vw`,
                md: '0px 20px',
            }}
            transform={`translateY(${x}px)`}
        >
            <Box
                marginBottom={`5vh`}
                position={`relative`}
                textAlign={`center`}
                fontSize={{base:`36px`, lg:`48px`}} 
                color={`#fff`}
            >[團隊介紹]</Box>

            <Flex
                width={`100%`}
                flexDirection={{
                    base: `column`,
                    md: `column`,
                }}
                padding={`10vh 0px`}
                justify={'center'}
                align={'center'}
            >
                <Flex 
                    w={`100%`}
                    maxWidth={`600px`}
                    flexWrap={`wrap`}
                    justifyContent={`center`}
                    gap={'5vw'}
                >
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(1)} pointerEvents={`fill`} cursor={`pointer`} width={`100px`} src={`assets/team_1.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(2)} pointerEvents={`fill`} cursor={`pointer`} width={`130px`} src={`assets/team_2.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(3)} pointerEvents={`fill`} cursor={`pointer`} width={`130px`} src={`assets/team_3.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(4)} pointerEvents={`fill`} cursor={`pointer`} width={`85px`} src={`assets/team_4.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(5)} pointerEvents={`fill`} cursor={`pointer`} width={`70px`} src={`assets/team_5.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(6)} pointerEvents={`fill`} cursor={`pointer`} width={`100px`} src={`assets/team_6.webp`}/>
                    </Flex>
                    <Flex 
                        width={`unset`}
                        padding={`2vh 0px`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        
                    >
                        <Image cursor='pointer' onClick = {() => setActiveID(7)} pointerEvents={`fill`} cursor={`pointer`} width={`90px`} src={`assets/team_7.webp`}/>
                    </Flex>
                </Flex>
                <Box h = {`5vh`}/>
                <Flex
                    justifyContent={`center`} 
                    position={`relative`}
                >   
                    <TextBox>
                        <Box w={`100%`}>{index[activeID].content}</Box>
                    </TextBox>
                </Flex>
            </Flex>
        </Box>
    )
}