import { ChakraProvider, Box } from '@chakra-ui/react';
import { useEffect, useState, createContext, useContext } from 'react';
import utils from './lib/utils';
import ScrollView from './components/ScrollView';
import FrameView from './components/FrameView';
import theme from './components/theme/theme';
import MenuView from './components/MenuView';
import Mouse from './components/Mouse';
//建立一個Context
const Context = createContext();
export const useApp = () =>{
    const value = useContext(Context);
    return value;
}

//
export default function App(){
    
    //建立一個捲動狀態
    const [scrollTop, setScrollTop] = useState(0);
    

    //建立一個物件狀態
    const state = ((struct) => {
        const e = useState;
        const [data, update] = e(struct);
        let payload = utils.obj.copy(data);
        Object.defineProperty(payload, 'set', {
            value: (callback, async = false) => {
                update((prev) => {
                    if(async) payload = utils.obj.copy(prev);
                    callback(payload);
                    return payload;
                });
            }
        });
        return payload;
    })({
        sectionLength: 7,
        sectionSize: 1, 
        windowHeight: window.innerHeight * 2,
        userName: '',
        planet: 0,
        windowWidth: window.innerWidth, 
        targetTop: 0,
        nameFieldMsg: null,
        qaIndex: -1,
        clickY: -1,
    });

    //監聽視窗高度變化
    useEffect(() => {
        window.addEventListener('resize', () => {
            state.set((e) => {
                e.windowHeight = window.innerHeight * 2;
                e.windowWidth = window.innerWidth;
            }, true);
        });
    }, [window.innerHeight]);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            //console.log(e);
            state.set((prev) => {
                prev.clickY = e.y;
            }, true);
        })
    }, []);

    
    //計算目前所在的區塊
    const section = Math.ceil(scrollTop / state.windowHeight);
    const sectionOffsetTop = state.windowHeight * section;
    const view = {
        section,
        top: sectionOffsetTop - scrollTop,
        bottom: sectionOffsetTop - scrollTop - state.windowHeight,
        x: function (map) {
            if(map[section] == undefined){
                return null;
            }else{
                const reach = (map[section].reach != undefined) ? map[section].reach : 0;
                let x = (this.top - (reach * state.windowHeight)) * map[section].speed;
                if(map[section].proxy != undefined) x = map[section].proxy(x);
                return x;
            }
        },
        range: function (start, end, range = 100) {
            const startOffsetTop = state.windowHeight * start;
            const endOffsetTop = state.windowHeight * end;
            const length = endOffsetTop - startOffsetTop;
            let transform = ((scrollTop - startOffsetTop) / length) * range;
            if(transform < -range) transform = -range;
            if(transform > range) transform = range;
            return {transform, scale: (transform / 2 + range / 2)};
        }
    }

    //console.log('view.section ->', view.section);
    return (
        <ChakraProvider theme = {theme}>
            <Context.Provider value={{state, view, scrollTop}}>
                <Box 
                    // pointerEvents={'none'}
                    position = {'fixed'}
                    inset = {'0px'}
                    backgroundColor = {'#000'}
                >
                    
                    <MenuView />
                    <Mouse />
                    <ScrollView  setScrollTop = {setScrollTop}/>
                    <FrameView />
                </Box>
            </Context.Provider>
        </ChakraProvider>
    );
}