import {Box, Flex} from "@chakra-ui/react";
export default ({bgColor = '#000', children, padding = '0px', w = 'contain', stairs = 4}) => {
    let size = 4;
    let arr = [];
    let step = [];

    for(let i = 0; i < stairs; i++){
        step.push(i);
    }

    if(w == 'auto') w = 'fit-content';
    if(w == 'contain') w = '600px';
    if(w == 'fill') w = '100%';


    step.map((x) => {
        step.map((y) => {
            let color = 'transparent';
            const xRev = (step.length - 1) - x;
            if((y <= 1 || x >= 2) && (xRev + y) < step.length) color = bgColor;
            if(x === y) color = '#fff';
            arr.push({x, y, color});
        })
    });

    const corner = (rotate = 0) => (
        <Flex
            position={'relative'}
            rotate={`${rotate}deg`}
            width = {`${size * step.length}px`}
            height = {`${size * step.length}px`}
            transform={`rotate(${rotate}deg)`}
        >
            {arr.map((d) => {
                return (
                    <Box
                        key={`${d.x}-${d.y}`}
                        position={'absolute'}
                        width = {`${size}px`}
                        height = {`${size}px`}
                        backgroundColor = {d.color}
                        top = {`${d.x * size}px`}
                        left = {`${d.y * size}px`}
                    />
                )
            })}
        </Flex>
    )

    const bound = (r1, r2, type) => (
        <Flex width={'100%'} zIndex={0}>
            <Flex flexBasis={'content'}>{corner(90*r1)}</Flex>
            <Flex {...{flex:'1', [type]: `${size}px solid #FFF`, backgroundColor: bgColor}} />
            <Flex flexBasis={'content'} >{corner(90*r2)}</Flex>
        </Flex>
    )

    const side = (type = 0) => (
        <Flex {...{flexBasis:'content', [type]: `${size}px solid #FFF`, backgroundColor: bgColor}}>
            <Box width = {`${size * step.length}px`}></Box>
        </Flex>
    )

    return (
        <Flex 
            // width = {{
            //     base: '80vw',
            //     md: 'fit-content',
            // }}
            position={'relative'}
            width = {w}
            maxWidth={'95vw'}
            flexDirection={`column`}
        >

            {(stairs == 3) && <Box zIndex={5} bgColor={'rgba(255, 255, 255, 0.4)'} position={'absolute'} top={`${size}px`} right={`${size * 2}px`} left={`${size * 2}px`} height={`${size}px`} />}
            {(stairs == 3) && <Box zIndex={5} bgColor={'rgba(0, 0, 0, 0.3)'} position={'absolute'} bottom={`${size}px`} right={`${size * 2}px`} left={`${size * 2}px`} height={`${size}px`} />}

            {(stairs == 3) && <Box zIndex={5} bgColor={'rgba(255, 255, 255, 0.4)'} position={'absolute'} bottom={`${size * 2}px`} top={`${size * 2}px`} left={`${size}px`} width={`${size}px`} />}
            {(stairs == 3) && <Box zIndex={5} bgColor={'rgba(0, 0, 0, 0.3)'} position={'absolute'} bottom={`${size * 2}px`} top={`${size * 2}px`} right={`${size}px`} width={`${size}px`} />}

            {bound(3, 0, 'borderTop')}
            <Flex width={'100%'}>
                {side('borderLeft')}
                <Flex 
                    width = {{
                        base: '100%',
                        md: '100%',
                    }}
                    padding={padding} 
                    color={(bgColor == '#000')? `#FFF`: `#000`}
                    backgroundColor={bgColor}
                    flex = {1}
                    justifyContent={`center`}
                >
                    <Box
                        width = {{
                            base: '100%',
                            md: '100%',
                        }}
                    >{children}</Box>
                </Flex>
                {side('borderRight')}
            </Flex>
            {bound(2, 1, 'borderBottom')}
        </Flex>
    )
}