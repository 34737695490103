import { Box } from "@chakra-ui/react";
import { useApp } from "../../App";

export default () => {

    const app = useApp();
    const x = app.view.x({
        2:{
            speed: 0.6,
        },
        3:{
            speed: 0.6,
            reach: 1,
        },
    });

    let text = "酷童實驗所希望成為臺灣各大家族的幫手。\n我們將把 ESG 永續精神，置入家族文化中，讓接班的下一代對創造正向影響力產生熱情與內在動機。\n並透過情緒教育帶著孩子學習利己利他，建立服務精神，發起利他行動！";
    if(app.state.windowWidth < 768) text = "酷童實驗所希望成為臺灣各大家族的幫手。\n我們將把 ESG 永續精神，置入家族文化中，讓接班的下一代對創造正向影響力產生熱情與內在動機。\n並透過情緒教育帶著孩子學習利己利他，建立服務精神，發起利他行動！";
    
    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
        >
            <Box
                width={'auto'}
                padding={'20px'}
                color={'#fff'} 
                transform={`translateY(${x}px)`}
            >
                {text.split("\n").map(function(item, idx) {
                    return (
                        <Box padding={`5px`}  textAlign={`center`} key={idx}>
                            {item}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}