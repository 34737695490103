import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";
export default () => {

    const app = useApp();
    const x = app.view.x({
        22:{
            speed: 0.6,
        },
        23:{
            speed: 0.6,
            reach: 1,
        },
    });

    const url = `assets/rocket_cube.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    const move = app.view.range(22, 23, 80).scale;

    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
            transform = {`translateX(${move-40}vw)`}
        >
            <Image 
                src = {url}
                transform={`translateY(${x}px)`}
                width={`40vw`}
            />
        </Box>
    )
} 