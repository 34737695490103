import { Box, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ExtendedItem from "../view/ExtendedItem";

export default () => {

    const app = useApp();
    const x = app.view.x({
        38:{
            speed: 0.6,
        },
        39:{
            speed: 0.6,
            reach: 1,
       },
   },);

    const map = [
        { 
            label: "課程是否可以請假？請假要如何補課？",
            content: `報名期間將調查各家庭無法出席的日期，若過半數，將協調替換課程時間。
            \n​​因為所有課程的形式主要以討論、體驗為主，會根據每一堂課孩子的反應、當下的狀態，講師會有不同的引導，即使補課也難有一樣的成效，故不提供補課服務。
            ​\n​若已經有注意到無法配合的日期，請在計畫報名時註記，能預先知道哪天要請假，講師能先行在課程上調整孩子的分工。
            ​\n​遇到不可抗力因素而臨時請假：下一次課堂請孩子早一點到教室，小組長或講師會協助孩子同步請假時段的討論內容。
            `
        },
        { 
            label: "課堂會有回家作業嗎？",
            content: `回家作業的量不會太多，主要會根據孩子課堂討論的狀況而定：如訪談家長，當孩子主動與爸爸媽媽分享 / 尋求幫忙時，可以再請家長跟孩子分享您的經驗與看法呦！
            作業的目的主要是強化上課討論的內容，如果真的沒時間做也仍可持續出席課程。但上課缺乏分享內容，而影響學習體驗，仍建議提醒孩子要完成作業。
            `
        },
        { 
            label: "家長可以如何了解孩子的課堂狀況？",
            content: `七個月的計畫期，小組都會由小組長陪伴並協助孩子做課程紀錄（包含文字與照片），會於每次課堂結束後兩週內提供給家長，若有特別想觀察的面向，家長也可以提前跟我們說，會再請講師與小組長特別留意。
            `
        },
        { 
            label: "陪伴孩子的小組長是什麼背景、如何挑選？",
            content: `小組長都是具備長期陪伴孩童經驗，且受過扎實專業培訓（ 如：反思引導、薩提爾溝通法、PBL 培訓）的一線教育工作者。
            `
        },
        { 
            label: "家族不只有一位孩子，可以一起報名嗎？",
            content: `非常歡迎！同一家庭兄弟姐妹可一起加入計畫，但每一家族孩童報名上限為三名，且同家族的孩子會被安排進入不同的分組，擴大孩子的社交圈。
            `
        },
        { 
            label: "如果是比較內向的孩子適合此計畫嗎？",
            content: `對我們來說所有的孩子都是最棒的！學習與不同特質的孩子相處，也是我們想帶給參與計畫孩子的經驗。團隊的講師與助教們也會針對組內每位孩子的狀況即時調整組內任務。比如說，鼓勵較安靜內向的孩子，把感受寫在便利貼上，代替把想法「說」出來！我們很樂意陪著孩子一起成長。
            `
        },
        { 
            label: "能協助有特殊教育需求的孩子嗎？",
            content: `我們尊重每個孩子的獨特性，包含特殊教育需求的孩子。但，由於目前計畫無聘用特教師資，我們可能無法提供完全專門化的支持給這些孩子。若您仍想鼓勵孩子參與這支計畫，歡迎與我們聯繫，進一步確認孩子的需求和我們團隊能夠提供的支持範圍。我們將盡力找到最適合的解決方案，以確保每個孩子在計畫中都能夠獲得積極地參與和成長。
            `
        },
        { 
            label: "為什麼孩子的年齡層鎖定在國小中高年級至國中呢？",
            content: `這個年齡階段的孩子開始有更多自己的想法和意見，也更容易被同儕影響，對於社會情緒智力的需求會隨之提高。因此，我們希望能在此階段培育孩子們的社會情緒智力，以及提高孩子們的社會責任感。
            我們也清楚地明白每個孩子的發展速度都不相同。如果您的孩子大於或小於我們期待的年齡，也歡迎連繫專人，討論孩子是否適合計畫的課程內容。
            `
        },
        { 
            label: "三天兩夜戶外活動受天氣影響的應變措施？",
            content: `我們非常重視每個孩子的安全與健康，如果遇到天氣等不可抗力的因素，我們將採取相應的應變措施。主辦單位有權決定是否需要調整時間表、延期或取消活動，屆時，會即時與各位聯繫，提供相關的安排和指示。`
        },
        { 
            label: "對疫情相關的應變措施是？",
            content: `防疫措施如以下 5 點，且視疫情滾動式修正：
            如孩子確診或疑似接觸，敬請在家休養。
            教室對外窗保持開窗 15 公分，每日落實清潔與消毒。
            所有人員進教室前需量測體溫與酒精消毒，超過 37.5 度者敬請返家休養。
            如於室內用餐，餐點為一人一份，不交談、不共食。
            非中午用餐時段，所有人員一律配戴口罩。
            `
        },
        { 
            label: "孩子參與課程後的下一步是什麼？明年是否還會有這支計畫？",
            content: `非常鼓勵孩子繼續發展和深化所學的內容和技能。在 2024 年 4 月成果發表後，如果孩子表現出進一步的發展意願，我們將支持他們參與相關競賽或提供進一步的學習機會。
            硬派接班人每一期計畫將分為兩階段，每年依相似時程持續舉辦：
            第一階段（9月-4月）為活動培力：從認識議題到發想解決方案，孩子們將從自身出發思考如何投入。
            第二階段（5月-8月）為影響力擴散：我們將協助積極行動的孩子們參與公益競賽，例如：瑪麗亞公益競賽和 ACT 夢想家。
            第二期計畫預計一半的名額將開放給新生，另一半則保留給舊生。舊生入選條件將根據本期課堂出席狀況進行評估。入選舊生也將獲得推薦機會，以便選出其他合適的孩子加入第二期計畫。
            `
        },
        { 
            label: "對於 ESG、企業永續經營、家族治理還沒有了解太多，可以加入計畫嗎？",
            content: `沒問題，歡迎您的加入！無論是否具備相關知識，都可以參與計畫。我們的目的是提供交流的機會以及相關資源，幫助您了解相關概念的重要性、實踐方法和永續經營步驟。
            `
        },
        { 
            label: "你們能怎麼協助想發展公益項目的家族呢？",
            content: `我們提供一對一的諮詢服務，與您深入討論並了解您家族的特色、價值觀以及感興趣的公益領域。後續提供相應的資源與人脈，陪伴您一同發展家族特色的慈善方案，實踐利他行動。如果您有需求，我們將提供您「基金會設立指南」，與您分享設立流程和秘訣。
            `
        },
        { 
            label: "若有意願捐贈《酷童出任務》是否會有捐款收據？",
            content: `是的，繳納的費用會提供捐款收據。如果您在 2023 年 10 月 22 日前完成捐款，我們將於 2023 年 10 月 28 日的 ESG 名人下午茶敘當天提供捐款收據給您。
            `
        },
        { 
            label: "酷童實驗所是什麼單位？",
            content: `酷童實驗所 為好好星球文化基金會的子品牌，致力於讓台灣所有的孩童都能接觸利他生命教育，在成長環境中，建立服務精神。
                        \n​​酷童實驗所由三大元素所組成：硬派接班人、硬派俱樂部、酷童出任務。
                        \n​硬派接班人：支持孩子透過認識情緒學習理解自己、尊重他人、關懷社群，建立利他精神。
                        \n​硬派俱樂部：陪伴重視 ESG 的家族企業結識其他有慈善精神的家族，將 ESG 原則融入家族治理中。
                        \n​酷童出任務：將影響力傳出去，贊助偏遠地區孩子體驗永續教育，達成教育資源均等化。
                        `
        },
        { 
            label: "如何保護孩子的隱私以及實施性平保護措施？",
            content: `為確保孩子在計畫中受到充分的保護和安全。與孩子接觸的所有夥伴都將遵守以下規範：
                    \n​​提供「良民證」或相關的背景調查文件，以確保無類似刑事案件和適當的信譽。
                    \n​在與孩子和家長進行互動之前，夥伴將簽署「保護條款」，明確規範他們的行為和責任，以了解和遵守相關的性平保護政策和措施。
                    \n​​我們會提供相關的培訓資源和資料，幫助夥伴更好地理解保護孩子的重要性和方法。`
        },
    ]


    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
            width={{
                base: `100%`,
                md: '70vw',
                lg: '60vw',
            }}
            height={'fit-content'} 
            padding={'0px 20px'}
            transform={`translateY(${x}px)`}
        >
            <Box
                marginBottom={`5vh`}
                position={`relative`}
                textAlign={`center`}
                fontSize={{base:`36px`, lg:`48px`}} 
                color={`#fff`}
            >[常見ＱＡ]</Box>
            {map.map((item, i) => (
                <ExtendedItem 
                    label={item.label}
                    index = {i}
                >
                    {item.content.split("\n").map(function(item, idx) {
                        return (
                            <Box
                                lineHeight={`150%`} 
                                margin={`0 auto`}  
                                padding={`5px`} 
                                key={idx}
                            >
                                {item}
                            </Box>
                        )
                    })}
                </ExtendedItem>
            ))}
            
        </Box>
    )
}