import { Box, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";
import sections from "../data/Sections";
import { useEffect, useState } from "react";

export default () => {

    const [click, setClick] = useState(false);

    const app = useApp();
    let dialogue = {
//         4: {
//             type: 'in',
//             text: `酷童實驗所希望成為臺灣各大家族企業的幫手。\n
// ​                   ​我們將把 ESG 永續精神，置入家族文化中，讓接班的下一代對創造正向影響力產生熱情與內在動機。並透過情緒教育帶著孩子學習利己利他，建立服務精神，發起利他行動！`
//         },
        5: {
            type: 'in',
            text: '西元  2530 年，地球被無盡的黑暗籠罩，\n世界上所有物種都失去了色彩，大家臉上的表情也被奪去....'
        },
        6: {
            type: 'in',
            text: '為了解決這項困境、同時也為了拯救地球，',
        },
    }

    //將段落對話加入
    sections.map((d) => {
        dialogue[d.startAt] = {...d.dialogue, type: 'in'};
        dialogue[d.startAt+1] = {...d.dialogue, type: 'out'};
    });

    useEffect(() => {
        setClick(false);
    }, [app.scrollTop]); 


    if(dialogue[app.view.section] == undefined){
        return null
    }else{
        const data = dialogue[app.view.section];
        let move, text;
        if(data.type == 'in'){
            move = app.view.range(app.view.section -1, app.view.section-0.3, data.text.length);
            text = data.text.slice(0, Math.ceil(move.transform));
            //console.log(move);
        }else{
            move = app.view.range(app.view.section, app.view.section+0.7, data.text.length);
            text = data.text.slice(0, Math.floor(move.transform)*-1);
        }

        if(click && data.detail != undefined) text = data.detail;
        
        //console.log(app.view.section, text, move);
        // if(data.nlToBr == undefined || data.nlToBr == false)text = text.replaceAll('\n', "");
        if(text.length > 0){
            return (
                <Flex
                    position={`absolute`}
                    inset={`0`}
                    zIndex={5} 
                    alignItems={`center`}
                    justifyContent={`center`}
                    fontSize={{base:`15px`, lg:`16px`}} 
                >
                    <Box 
                        pointerEvents = {'none'}
                        position = {'absolute'} 
                        bottom={`10vh`}
                        
                    >
                        <Box
                            width={{
                                base: '100%',
                                md: 'auto',
                            }}
                            padding={'10px'}
                            color={'#fff'} 
                        >
                            <TextBox>
                                <Box 
                                    textAlign={`left`}
                                    
                                >{text.split("\n").map(function(item, idx) {

                                    return (
                                        <Box
                                            lineHeight={`140%`} 
                                            margin={`0 auto`}  
                                            paddingBottom={`1px`} 
                                            key={idx}
                                        >
                                            {item.split("```").map(function(char, i) {
                                                char = char.replaceAll("`", "");
                                                if(i == 1){
                                                    return(
                                                        <span style={{cursor:'pointer', pointerEvents:'fill'}} onClick={() => {
                                                            window.open('http://line.me/R/ti/p/@childgoodlab');
                                                        }}>{char}</span>
                                                    )
                                                }else{
                                                    return(
                                                        <span>{char}</span>
                                                    )
                                                }
                                            })}
                                        </Box>
                                    )
                                })}</Box>
                            </TextBox>
                        </Box>
                        
                    </Box>

                    {(data.detail != undefined && (
                        <Box
                            pointerEvents = {'fill'}
                            position = {'absolute'} 
                            bottom={`10px`}
                            // onTouchStart={() => setClick(true)}
                            // onTouchEnd={() => setClick(false)}
                            // onMouseDown={() => setClick(true)}
                            // onMouseUp={() => setClick(false)}
                            onClick={() => setClick(!click)}
                            // onClick={() => {
                            //     app.state.set((state) => {
                            //         alert('aaa');
                            //     })
                            // }}
                            cursor={`pointer`}
                        >
                            <TextBox
                                bgColor = {`#A7BFC1`}
                                padding = {`0px`} 
                                w = {`auto`}
                                stairs = {3}
                            >詳細資訊</TextBox>
                        </Box>
                    ))}

                    {(data.btn != undefined && (
                        <Box
                            pointerEvents = {'fill'}
                            position = {'absolute'} 
                            bottom={`10px`}
                            onClick={data.btn.func}
                            cursor={`pointer`}
                        >
                            <TextBox
                                bgColor = {`#A7BFC1`}
                                padding = {`0px`} 
                                w = {`auto`}
                                stairs = {3}
                            >{data.btn.label}</TextBox>
                        </Box>
                    ))}
                </Flex>
            )
       }else{
            return null;
        }
    }
}