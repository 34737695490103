import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";
import { motion } from "framer-motion";





export default () => {
    const start = 23;
    const end = 34;

    const app = useApp();
    let map = {
        [start]:{
            speed: 1,
        },
        [end]:{
            speed: 1,
            reach: 1,
        },
    };
    for(let i = start + 1; i < end; i++){
        map[i] = {
            speed: 0,
        }
    }
    const x = app.view.x(map);

    

    const url = `assets/cube.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        const i = 0;

        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                zIndex={0}
            >
                <motion.div
                      animate={{
                        x: [-3, 3, 3, -3],
                        y: [-3, -3, 3, -3],
                        transition: {
                          delay: 0.3,
                          repeat: Infinity,
                          duration: 3,
                          damping: 1.9
                        }
                      }}
                >
                    <Image 
                        src = {url} 
                        transform = {`translateY(${x}px)`}
                        height = {{
                            base: `12vh`,
                            md: '16vh',
                        }}
                    />
                </motion.div>
            </Box>
        )
    }
}