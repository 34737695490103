import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../App";
import ImagePreloader from "image-preloader";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default () => {
    const [counter, setCounter] = useState(5);
    const app = useApp();
    const url = `assets/mouse.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setCounter((prev) => {
    //             return prev + 1;
    //         });
    //     }, 2000);
    // }, [counter]);

    // useEffect(() => {
    //     setCounter(0);
    // }, [app.scrollTop]);

    // console.log(counter);
    let opacity = (counter > 10)? 1:0;
    return (
       <Box
        pointerEvents={'none'}
        position = {'fixed'}
        top={'0px'}
        left={'0px'}
        bottom={'0px'}
        right={'0px'}
       >
            <motion.div
                style={{
                    position: 'absolute',
                    right: '20px',
                    bottom: '20px',
                }}
                animate={{
                    opacity: [0, opacity, 0],
                    transition: {
                        repeat: Infinity,
                        duration: 3,
                        damping: 1.9
                    }
                }}
            >
                <Image 
                    src = {url} 
                    width={'30px'}
                    height={'auto'}
                />
            </motion.div>
       </Box>
    )
    
}