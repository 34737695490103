import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";
import { motion } from "framer-motion";


export default () => {

    const app = useApp();
    const x = app.view.x({
        21:{
            speed: 1,
        },
        22:{
            speed: -1,
            reach: 1,
        },
    });

    const url = `assets/planet_emotion_nomonster.webp`;
    const url2 = `assets/planet_enviroment.webp`;
    const url3 = `assets/planet_emotion_front.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
        ImagePreloader.simplePreload(url2);
    }, []);
    

    if(x == null){
        return null;
    }else{
        const move = app.view.range(21, 22, 50).transform;
        return (
            <Box 
                position = {'absolute'} 
                top = {'0px'}
                left={'0px'} 
                width={'100vw'}
                height={'200vh'}
                //overflow={'hidden'} 
                transform={`translateY(${x}px)`}
                zIndex={1}
            >
    
                <Box
                    position={'absolute'}
                    width={'100vw'}
                    height={'0px'}
                    top = {'0px'}
                    left = {'0px'}
                >
                    <Box
                        position={'absolute'}
                        width={'100vw'}
                        height={'fit-content'}
                        bottom={'0px'}
                        left={'0px'}
                    >
    
                        <Image 
                            width={`100%`} 
                            src={`assets/bg_transfer.svg`}
                        />
                    </Box>
                </Box>
                <Box
                    position={'absolute'}
                    width={{base:`${50+(app.state.planet * 50)}vw`,md:`${50+(app.state.planet * 45)}vw`}}
                    height={'100vh'}
                    top = {'0px'}
                    left = {'0px'} 
                    transition={'width 0.5s ease'}
                    overflow={'hidden'}
                >
                    <Box
                        position={'absolute'}
                        // backgroundImage={`url(${url2})`}
                        // backgroundSize={'contain'}
                        // backgroundPosition={'bottom'}
                        // backgroundColor={'#A6C0B3'}
                        // bgRepeat={'no-repeat'}
                        top = {'0px'}
                        left = {'0px'} 
                        height = {'100vh'} 
                        width={'100vw'}
                    >
                        <Box
                            position={'absolute'}
                            inset={'0px'}
                            backgroundImage={`url(${url2})`}
                            backgroundSize={'cover'}
                            backgroundPosition={'top'}
                            backgroundColor={'#A6C0B3'}
                            bgRepeat={'no-repeat'}
                        />
                        <Box
                            position={'absolute'}
                            inset={'0px'}
                            backgroundImage={`url(${url3})`}
                            backgroundSize={'cover'}
                            backgroundPosition={'top'}
                            // backgroundColor={'#A6C0B3'}
                            bgRepeat={'no-repeat'}
                            // opacity={0.5}
                            transform={`translateY(${move}vh)`}
                        />
                        {(app.state.planet != 0) && (
                                <Box
                                marginBottom={`10px`}
                                position={`relative`}
                                textAlign={`center`}
                                fontSize={{base:`36px`, lg:`48px`}} 
                                color={`black`}
                                mt='10vh'
                            >[環境星球]</Box>
                        )}
                        {(app.state.planet != 0) && (
                            <Box
                                textAlign={`center`}
                                width={'auto'}
                                fontSize={{base:`20px`, lg:`34px`}} 
                                color={`black`}
                                display={'flex'}
                                alignItems={'center'} 
                                flexDirection={'column'}
                            >自然探索</Box>
                        )}
                    </Box>
                </Box>
                <Box
                    position={'absolute'}
                    width={{base:`${50-(app.state.planet * 50)}vw`,md:`${50-(app.state.planet * 45)}vw`}}
                    // width={`${50-(app.state.planet * 40)}vw`} 
                    height={'100vh'}
                    top = {'0px'}
                    right={'0px'} 
                    transition={'width 0.5s ease'}
                    overflow={'hidden'}
                >
                    <Box
                        position={'absolute'}
                        // backgroundImage={`url(${url})`}
                        // backgroundSize={'contain'}
                        // backgroundPosition={'bottom'}
                        // backgroundColor={'#A6C0B3'}
                        // bgRepeat={'no-repeat'}
                        top = {'0px'}
                        right = {'0px'} 
                        height = {'100vh'} 
                        width={'100vw'}
                    >
                        <Box
                            position={'absolute'}
                            inset={'0px'}
                            backgroundImage={`url(${url})`}
                            backgroundSize={'cover'}
                            backgroundPosition={'top'}
                            backgroundColor={'#A6C0B3'}
                            bgRepeat={'no-repeat'}
                        />
                        {/* <Box
                            position={'absolute'}
                            inset={'0px'}
                            backgroundImage={`url(${url3})`}
                            backgroundSize={'cover'}
                            backgroundPosition={'top'}
                            backgroundColor={'#A6C0B3'}
                            bgRepeat={'no-repeat'}
                            opacity={0.5}
                            transform={`translateY(${move}vh)`}
                        /> */}
    
                        <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `1vh`, '0vh'],
                                transition: {
                                    delay: -1,
                                    repeat: Infinity,
                                    duration: 3,
                                    damping: 1.9
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster8.webp)`}
                                backgroundSize={'cover'}
                                backgroundPosition={'center'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `2vh`, '0vh'],
                                transition: {
                                    delay: -3,
                                    repeat: Infinity,
                                    duration: 4,
                                    damping: 3
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster9.webp)`}
                                backgroundSize={'cover'}
                                backgroundPosition={'conter'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div>
                        {/* <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `1.73vh`, '0vh'],
                                transition: {
                                    delay: 2,
                                    repeat: Infinity,
                                    duration: 3,
                                    damping: 1.9
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster3.webp)`}
                                backgroundSize={'contain'}
                                backgroundPosition={'bottom'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `0.9vh`, '0vh'],
                                transition: {
                                    delay: -1,
                                    repeat: Infinity,
                                    duration: 5,
                                    damping: 1.9
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster4.webp)`}
                                backgroundSize={'contain'}
                                backgroundPosition={'bottom'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `4vh`, '0vh'],
                                transition: {
                                    delay: -2,
                                    repeat: Infinity,
                                    duration: 2,
                                    damping: 1.9
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster5.webp)`}
                                backgroundSize={'contain'}
                                backgroundPosition={'bottom'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div>
                        <motion.div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                inset: '0px',
                            }}
                            animate={{
                                y: ['0vh', `.5vh`, '0vh'],
                                transition: {
                                    delay: -3,
                                    repeat: Infinity,
                                    duration: 4,
                                    damping: 1.9
                                }
                            }}
                        >
                            <Box
                                position={'absolute'}
                                inset={'0px'}
                                backgroundImage={`url(assets/planet_emotion_monster6.webp)`}
                                backgroundSize={'contain'}
                                backgroundPosition={'bottom'}
                                bgRepeat={'no-repeat'}
                            />
                        </motion.div> */}
                        {
                            // ['1', '2', '3', '4', '5', '6'].map((i) => {
                            //     return (
                                    // <motion.div
                                    //     style={{
                                    //         width: '100%',
                                    //         height: '100%',
                                    //         backgroundColor: 'transparent',
                                    //         position: 'absolute',
                                    //         inset: '0px',
                                    //     }}
                                    //     animate={{
                                    //         y: ['0vh', `${-15 - i}vh`, '0vh'],
                                    //         transition: {
                                    //             delay: -i,
                                    //             repeat: Infinity,
                                    //             duration: 3,
                                    //             damping: 1.9
                                    //         }
                                    //     }}
                                    // >
                                    //     <Box
                                    //         position={'absolute'}
                                    //         inset={'0px'}
                                    //         backgroundImage={`url(assets/planet_emotion_monster${i}.webp)`}
                                    //         backgroundSize={'contain'}
                                    //         backgroundPosition={'bottom'}
                                    //         bgRepeat={'no-repeat'}
                                    //     />
                                    // </motion.div>
                                // )
                            // })
                        }
                        {(app.state.planet != 0) && (
                            <Box
                                marginBottom={`10px`}
                                position={`relative`}
                                textAlign={`center`}
                                fontSize={{base:`36px`, lg:`48px`}} 
                                color={`black`}
                                mt='10vh'
                            >[情緒星球]</Box>
                        )}
                        {(app.state.planet != 0) && (
                            <Box
                                textAlign={`center`}
                                width={'auto'}
                                fontSize={{base:`20px`, lg:`34px`}} 
                                color={`black`}
                                display={'flex'}
                                alignItems={'center'} 
                                flexDirection={'column'}
                            >建立社會情緒智力</Box>
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
}