import { Box, Flex, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";
import { useState } from "react";

export default () => {

    const [click, setClick] = useState(false);
    const index = {
        '-1': {
            content: '透過專業引導師帶領孩子體驗專案導向教育 PBL 遊戲，讓孩子們在系列課程中共同定義問題、發想行動、分工執行，從中建立社會情緒智力，了解自己的情緒和想法，並實際練習情緒管理技巧。',
            detail: `日期：\n
            2023 年 12 月 09 日（六）13:00-17:00\n
            2023 年 12 月 16 日（六）13:00-17:00\n
            2024 年 01 月 06 日（六）13:00-17:00\n
            2024 年 01 月 14 日（日）13:00-17:00（課程在週日）\n
            2024 年 02 月 24 日（六）10:00-16:00（課時較長）\n
            2024 年 03 月 16 日（六）13:00-17:00\n
            2024 年 03 月 23 日（六）13:00-17:00\n
            地點：台北市內湖區（完成報名後會收到具體資訊）\n
            師資：臺灣學次方創意學習協會
            `
        },
        '0': {
            content:'太空梭即將降落\n請選擇降落地點'
        },
        '1': {
            content: '三天兩夜在山野中安全自在地探索生態，讓孩子們親自體驗不同於城市的野外生活，從團體互動情境中學習面對衝突的方式。',
            detail: `日期：2023 年 11 月 10 日（五）- 2023 年 11 月 12 日（日）\n
            地點：新北市烏來區（完成報名後會收到具體資訊）\n
            師資：PNG思維行動`
        },
    }
    const app = useApp();
    const x = app.view.x({
        20:{
            speed: 1,
        },
        21:{
            speed: 0,
        },
        22:{
            speed: -1,
            reach: 1,
        },
   },);

    if(x == null){
        return null;
    }else{

        const text = (click)? index[app.state.planet].detail:index[app.state.planet].content;
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                zIndex={5}
                transform={`translateY(${x}px)`}
                inset={'0'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Box position={`absolute`} bottom={`11.5vh`}>
                    <TextBox>
                        {text.split("\n").map(function(item, idx) {
                            return (
                                <Box padding={`5px`}  textAlign={`center`} key={idx}>
                                    {item}
                                </Box>
                            )
                        })}
                    </TextBox>
                </Box>
    
                {
                    (index[app.state.planet].detail != undefined) && (
                        <Box
                            pointerEvents = {'fill'}
                            position = {'absolute'} 
                            bottom={`10px`}
                            // onMouseDown={() => setClick(true)}
                            // onMouseUp={() => setClick(false)}
                            onClick={() => {
                                setClick(!click)
                            }}
                            cursor={`pointer`}
                        >
                            <TextBox
                                bgColor = {`#A7BFC1`}
                                padding = {`0px`} 
                                w = {`auto`}
                                stairs={3}
                            >詳細資訊</TextBox>
                        </Box>
                    )
                }
            </Box>
        )
    }
}