import { Flex, Box } from '@chakra-ui/react';

export default ({cal, date, children, style}) => {
    return (
        <Flex
            flexDirection={'row'}
            {...style}
        >
            <Box 
                flexBasis={'content'}
                padding={'2px 0px'}
            >
               <Flex
                    width={{base:'50px', md:'70px'}}
                    height={`fit-content`}
                    border={`2px solid #fff`}
                    flexDirection={'column'}
                    alignItems={'center'}
               >
                    <Box 
                        width={'100%'} 
                        height={'3px'}
                        backgroundColor={'#fff'}
                    />
                    <Box 
                        flex={1} 
                        padding={'0px'}
                        fontSize={{base:'1.8rem', md:'2.8rem'}}
                        lineHeight={'100%'}
                    >
                            {cal[0]}
                    </Box>
                    <Box 
                        width={'80%'} 
                        height={'2px'}
                        backgroundColor={'#fff'}
                    />
                    <Box 
                        flex={1} 
                        padding={'3px 0px'}
                        fontSize={'0.8rem'}
                        lineHeight={'100%'}
                    >
                            {cal[1]}
                    </Box>
               </Flex>
            </Box>
            <Box flexBasis={'content'}>
                <Flex 
                    width={{base:'65px', md:'110px'}}
                    flexDirection={'column'}
                    padding={{base:'0px 5px 0px 5px', md:'0px 10px 0px 20px'}}
                >
                    {date.map((item, idx) => {

                        return (
                            <Flex
                                flexDirection={'row'}
                                fontSize={{base:'1.2rem', md:'1.6rem'}}
                            >
                                {item.map((d) => {
                                    if(d == null){
                                        return null;
                                    }else if(d == '_'){
                                        return (
                                            <Box 
                                                flex={1} 
                                                display={'flex'}
                                                alignItems={'center'}
                                                padding={'0px 10px'}
                                            >
                                                <Box 
                                                    width={`100%`} 
                                                    height={`2px`}
                                                    backgroundColor={'#fff'}
                                                ></Box>
                                            </Box>
                                        );
                                    }else{
                                        return (<Box>{d}</Box>)
                                    }
                                })}
                            </Flex>
                        )
                    })}
                </Flex>
            </Box>
            <Box 
                flex={1}
                color={'#fff'}
                fontSize={{base:'1.2rem', md:'1.6rem'}}
            >
                {children}
            </Box>
        </Flex>
    )
}