import { Box, Image, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {
    
    const app = useApp();
    
    const x = app.view.x({
        22:{
            speed: 1,
        },
        23:{
            speed: 1,
            reach: 1,
        },
    });


    const url = `assets/colorful_soil_with_flag.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                transform = {`translateY(${x}px)`}
                {...{
                    top: '0vh',
                    right: '0px',
                    // left: '0px',
                    height: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex: 0,
                }}
            >
                <Image 
                    src = {url} 
                    {...{
                        width: '60vw',
                    }}
                />
            </Box>
        )
    }
    
}