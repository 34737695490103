import { Flex, Box, Image } from '@chakra-ui/react';
import style from '../items/style/GemCircle.module.scss';
import ImagePreloader from "image-preloader";
import { useEffect } from 'react';

export default ({rotate, sort}) => {
    const size = '8vh';

    const url = `assets/gem${sort}.webp`;
    const url2 = `assets/gem${sort+6}.webp`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
        ImagePreloader.simplePreload(url2);
    }, []);

    return (
        <Flex
            pointerEvents={'none'}
            position={'absolute'}
            width={'64vh'} 
            height={'10vh'} 
            transform = {`rotate(${(sort - 1)*30}deg)`}
        >
            <Box flexBasis={'content'}>
                <Box 
                    width={size} 
                    height={size} 
                    transform = {`rotate(${-rotate - ((sort - 1) * 30)}deg)`}
                >
                    <Image
                        src={url} 
                        className={style.gemImage} 
                    />
                </Box>
            </Box>
            <Box flex = {1}></Box>
            <Box flexBasis={'content'}>
                <Box 
                    width={size} 
                    height={size} 
                    transform = {`rotate(${-rotate - ((sort - 1) * 30)}deg)`}
                >
                    <Image
                        src={url2}
                        className={style.gemImage} 
                    />
                </Box>
            </Box>
            
        </Flex>
    )
}