import { Box, Flex } from "@chakra-ui/react";
import { useApp } from "../../App";
import ScheduleSection from "../view/ScheduleSection";
import ScheduleItem from "../view/ScheduleItem";

export default () => {

    const app = useApp();
    const x = app.view.x({
        32:{
            speed: 0.7,
        },
        33:{
            speed: 0.7,
            reach: 1,
        },
    });


    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
           
            width={'100vw'}
            height={'fit-content'} 
            padding={{base:'0px 0px 0px 20px', md:'0px 10vw'}}
        >
            <Box
                position={`relative`}
                textAlign={`center`}
                fontSize={`3rem`} 
                color={`#fff`}
                transform={`translateY(${x}px)`}
            >[好星時程] </Box>
            <Box
                width={{
                    base: `100%`,
                    md: '70vw',
                    lg: '45vw',
                }}
                transform={`translateY(${x}px)`}
                marginTop={`5vh`}
                backdropFilter={`blur(10px)`}
            >
                <ScheduleSection lable = {'家長參與'} bg = {'#fff'}>
                    <ScheduleItem cal = {['09','SEP']} date = {[['24', null, '_']]}>
                        {/* <Box fontSize={{base:'12px', md:'14px'}}>體驗說明會</Box> */}
                        <Box>硬派座談 家庭座談會</Box>
                    </ScheduleItem>
                    <ScheduleItem style={{marginTop:'15px'}} cal = {['10','OCT']} date = {[['28', null, '_']]}>
                        <Box>好星太空船 啟程儀式</Box>
                        <Box>好星名人下午茶敘</Box>
                        <Box  fontSize={{base:'12px', md:'14px'}}>家族永續傳承經驗交流會</Box>
                        <Box width={`100%`} padding={`2px 0px 0px 2px`}>
                            <Box
                                height={`2px`} 
                                borderBottom={`1px dashed #FFF`}
                            />
                        </Box>
                    </ScheduleItem>
                </ScheduleSection>
                <ScheduleSection lable = {''} bg = {'transparent'}>
                    <ScheduleItem cal = {['11','Nov']} date = {[['10', '-', '12']]}>
                        <Box>環境星球 自然探索</Box>
                    </ScheduleItem>
                    <ScheduleItem style={{marginTop:'15px'}} cal = {['12','DEC']} date = {[['09', ',', '16']]}>
                        <Box
                            height={`100%`}
                            width={`50px`}
                            borderRight={`2px solid #FFF`}
                            borderTop={`2px solid #FFF`}
                        />
                    </ScheduleItem>
                    <ScheduleItem  cal = {['01','JAN']} date = {[['06', ',', '14']]}>
                            <Box>　情緒星球</Box>
                            <Box>　社會情緒智能</Box>
                    </ScheduleItem>
                    <ScheduleItem  cal = {['02','FEB']} date = {[['24', null, null]]}>
                        <Box
                            height={`100%`}
                            width={`50px`}
                            borderRight={`2px solid #FFF`}
                        />
                    </ScheduleItem>
                    <ScheduleItem  style={{marginBottom:'15px'}} cal = {['03','MAR']} date = {[['16', ',', '23']]}>
                        <Box
                            height={`50%`}
                            width={`50px`}
                            borderRight={`2px solid #FFF`}
                            borderBottom={`2px solid #FFF`}
                        />
                    </ScheduleItem>
                </ScheduleSection>
                <ScheduleSection lable = {''} bg = {'#fff'}>
                    <ScheduleItem cal = {['04','APR']} date = {[['14', null, '_']]}>
                        <Box width={`100%`} padding={`0px 0px 2px 2px`}>
                            <Box
                                height={`2px`} 
                                borderBottom={`1px dashed #FFF`}
                            />
                        </Box>
                        <Box>返航慶功宴成果發表</Box>
                        <Box>酷童出任務啟動儀式</Box>
                        
                    </ScheduleItem>
                </ScheduleSection>
            </Box>
        </Box>
    )
}