import { AnimatePresence, motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import TextBox from "./TextBox";
import { useApp } from "../../App";

export default ({label, children, index}) => {
    
    //const [state, setState] = useState(false);
    const app = useApp();
    const ref = useRef(null);


    useEffect(() => {
        const el = ref.current;
        const rect = el.getBoundingClientRect();
        if(app.state.clickY > rect.y && app.state.clickY <= rect.y + rect.height){
            console.log('click', index);
            if(app.state.qaIndex == index){
                console.log('index', -1);
                app.state.set((state) => {
                    state.qaIndex = -1;
                });
            }else{
                console.log('index', index);
                app.state.set((state) => {
                    console.log(state, index);
                    state.qaIndex = index;
                    console.log(state, index);
                });
            }

        }
    }, [app.state.clickY])

    return (
        <Box >
            <Box 
                ref={ref}
                padding={`7px 0px`}
                width={'fit-content'}
                cursor={'pointer'}
                pointerEvents={'none'}
                color={'#FFF'}
                onClick={() => {
                    //alert('click')
                    //setState((state) => !state);
                    // if(app.state.qaIndex )
                   
                    // if(app.state.qaIndex == index){
                    //     console.log('index', -1);
                    //     app.state.set((state) => {
                    //         state.qaIndex = -1;
                    //     });
                    // }else{
                    //     console.log('index', index);
                    //     app.state.set((state) => {
                    //         console.log(state, index);
                    //         state.qaIndex = index;
                    //         console.log(state, index);
                    //     });
                    // }

                    
                }}
            >
                {label}
            </Box>
            <AnimatePresence>
                {(app.state.qaIndex == index) && (
                    <motion.div
                        initial={{ opacity: 0, height: "auto", scale: 1 }}
                        animate={{ opacity: 1, height: "auto", scale: 1 }}
                        exit={{ opacity: 0, height: "auto", scale: 1}}
                        //transition={{ ease: "easeInOut", duration:.2 }}
                        transition={{ ease: "easeInOut", duration: 0 }}
                    >
                        <Box padding={`10px 0px`}>
                            <TextBox w={`fill`}>
                                {children}
                            </TextBox>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    )
}