//這裡放著各種資料中繼方法

const methods = {
    obj: {
        copy: (obj) => {
            let payload = Array.isArray(obj) ? [] : {};
            for (let key in obj) {
                if (obj[key] === null) payload[key] = null;
                else if (typeof obj[key] == "object") payload[key] = methods.obj.copy(obj[key]);
                else payload[key] = obj[key];
            }
            return payload;
        },
        size: (obj) => {
            let size = 0;
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        },
        toArr: (obj, map, min = false) => {
            let payload = [], counter = 0;
            obj = methods.obj.copy(obj);
            for (let key in obj) {
                let item = obj[key];
                payload[counter] = {};
                for (let i in item) {
                    if (map[i] == undefined) {
                        if (!min) payload[counter][i] = item[i];
                    } else payload[counter][map[i]] = item[i];
                }
                counter++;
            }
            return payload;
        },
        select: (obj, map) => {
            let payload = {};
            const proxy = (obj, key) => {
                return obj[key] !== undefined ? obj[key] : null;
            };
            for (let key in map) {
                payload[map[key]] = proxy(obj, Array.isArray(map) ? map[key] : key);
            }
            return payload;
        },
        extend: (obj, map, init = true) => {
            if (init) obj = methods.obj.copy(obj);
            if (map["_"] != undefined) obj = { ...obj, ...map["_"]({ obj }) };
            for (let i in obj) {
                if (typeof map[i] === "function")
                obj = { ...obj, ...map[i]({ val: obj[i], key: i, obj }) };
                else if (map[i] != undefined || Array.isArray(obj))
                obj[i] = methods.obj.extend(
                    obj[i],
                    map[Array.isArray(obj) ? 0 : i],
                    false
                );
            }
            return obj;
        },
    },
    arr: {
        toObject: (arr, key, obj = {}) => {
            if (arr == null) arr = [];
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i][key]] = arr[i];
            }
            return obj;
        },
    },
    int: {
        check: (str) => {
            if (typeof str === 'number') return true;
            if (typeof str !== "string") return false;
            return !isNaN(str) && !isNaN(parseFloat(str));
        },
    },
    img: {
        compress: (image, callBack) => {
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function () {
                let img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    let w = this.width,
                        h = this.height;
                    let maxSize = 1200;
                    let quality = 0.8;
                    if (w > h) {
                        if (w > maxSize) {
                            h = (h / w) * maxSize;
                            w = maxSize;
                        }
                    } else {
                        if (h > maxSize) {
                            w = (w / h) * maxSize;
                            h = maxSize;
                        }
                    }
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    canvas.setAttribute("width", w);
                    canvas.setAttribute("height", h);
                    ctx.drawImage(this, 0, 0, w, h);
                    let base64 = canvas.toDataURL(image.type, quality);
                    //let fileData = base64.replace(/^data:image\/[a-z]+;base64,/, "");
                    let fileData = base64;
                    //console.log(fileData);
                    callBack({ fileData });
                };
            };
        }
    },
    num: {
        random: (min, max) => {
            return Math.floor(Math.random() * (max - min) + min);
        }
    }
};

export default methods;
