import { Box, Flex, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import TextBox from "../view/TextBox";
import ImagePreloader from "image-preloader";
import { useEffect, useState } from "react";


const MidHeart = () => (
    <Flex
        position={`absolute`}
        inset={`0px`}
        pointerEvents={`none`}
        justifyContent={`center`}
        alignItems={`center`}
    >
        <Image src={`assets/heart.svg`} width={`28px`} />
    </Flex>
)

export default () => {

    const [activeID, setActiveID] = useState(2);
    const [task, setTask] = useState(['Advisor_2.webp']);

    const app = useApp();
    const x = app.view.x({
        44:{
            speed: 0.6,
        },
        45:{
            speed: 0.6,
            reach: 1,
       },
    },);

    useEffect(() => {
        for(let id = 1; id <= 8; id++){
            ImagePreloader.simplePreload(`assets/advisor/Advisor_${id}.webp`);
            for(let i = 0; i <= 40; i++){
                ImagePreloader.simplePreload(`assets/advisor/AdvisorTran_${id}_in${i.toString().padStart(2,"0")}.png`);
                ImagePreloader.simplePreload(`assets/advisor/AdvisorTran_${id}_out${i.toString().padStart(2,"0")}.png`);
            }
        }
    }, []);


    const index = {
        1: {
            name: '施惠文',
            title: '為台灣而教基金會執行長',
        },
        2: {
            name: '許書慈',
            title: '財團法人新北市好好星球文化基金會董事長',
        },
        3: {
            name: '劉蔚廷',
            title: '研華科技策略投資暨資金管理部協理',
        },
        4: {
            name: '徐聖惠',
            title: '臺北市私立靜心高中附設國小部副校長',
        },
        5: {
            name: '陳秉良',
            title: '橘子集團品牌總監 / 橘子關懷基金會執行長',
        },
        6: {
            name: '陳昱築',
            title: 'Impact Hub Taipei 共同創辦人暨執行長',
        },
        7: {
            name: '陳子倢',
            title: 'FunNow Group - Niceday 事業部總監',
        },
        8: {
            name: '劉建成',
            title: '臺灣大學創新設計學院 創新長',
        }
    }



    const NameBtn = ({selfID}) => {
        return (
            <Box 
                margin={`8px`}
                cursor={`pointer`}
                pointerEvents={`fill`}
                onClick = {() => {
                    console.log(activeID, selfID);
                    
                    let arr = [];
                    [activeID, selfID].map((id, idx) => {
                        for(let i = 0; i <= 40; i++){
                            arr.push(`AdvisorTran_${id}_${(idx == 0) ?'out':'in'}${i.toString().padStart(2,"0")}.png`);
                        }
                    })
                    arr.push(`Advisor_${selfID}.webp`);
                    //console.log(arr);
                    setTask(arr);
                    setActiveID(selfID);
                }}
            >
                {/* <TextBox w={'fit-contnet'}>
                    {index[selfID].name}
                </TextBox> */}
                <Box 
                    position={`relative`}
                   
                >
                    <Box
                        position={`absolute`}
                        inset={`0px`}
                        display={`flex`}
                        alignItems={`center`}
                        justifyContent={`center`}
                        color={`#fff`}
                    >{index[selfID].name}</Box>
                    <Image src={`assets/textbox_advisor_bg.webp`} width={`100%`} height={'auto'} /> 
                </Box>
            </Box>
        )
    }
    

    useEffect(() => {
        if(task.length > 1){
            setTimeout(() => {
                let newTask = [...task];
                newTask.shift();
                setTask(newTask);
            }, 30);
        }
    }, [task]);

   


    return (x == null) ? null : (
        <Box 
            pointerEvents = {'none'}
            position = {'absolute'} 
            width={{
                base: `100vw`,
                // md: '70vw',
                // lg: '60vw',
            }}
            height={'fit-content'} 
            padding={{
                base: `0px 2vw`,
                md: '0px 20px',
            }}
            transform={`translateY(${x}px)`}
        >
            <Box
                marginBottom={{base: `200px`, lg:`100px`}}
                position={`relative`}
                textAlign={`center`}
                fontSize={{base:`36px`, lg:`48px`}} 
                color={`#fff`}
            >[顧問名單]</Box>

            <Flex
                width={`100%`}
                flexDirection={{
                    base: `column-reverse`,
                    md: `column`,
                }}
                padding={`10vh 0px`}
            >
                <Flex 
                    width={`100%`}
                    justifyContent={`center`}
                    position={`relative`}
                >
                    {(app.state.windowWidth < 768 && <MidHeart />) }
                    <Flex flexBasis={`content`}>
                        <Flex flexDirection={`column`} position={`relative`}>
                            <Flex flexDirection={`row`}>
                                <NameBtn selfID = {1}/>
                                <NameBtn selfID = {2}/>
                            </Flex>
                            <Flex flexDirection={`row`}>
                                <NameBtn selfID = {3}/>
                                <NameBtn selfID = {4}/>
                            </Flex>
                            <MidHeart />
                        </Flex>
                    </Flex>
                    <Flex flex={{
                        base: 'unset',
                        md: '1',
                    }}></Flex>
                    <Flex flexBasis={`content`}>
                        <Flex flexDirection={`column`} position={`relative`}>
                            <Flex flexDirection={`row`}>
                                <NameBtn selfID = {5}/>
                                <NameBtn selfID = {6}/>
                            </Flex>
                            <Flex flexDirection={`row`}>
                                <NameBtn selfID = {7}/>
                                <NameBtn selfID = {8}/>
                            </Flex>
                            <MidHeart />
                        </Flex>
                    </Flex>
                </Flex>
                <Box h = {`5vh`}/>
                <Flex
                    justifyContent={`center`} 
                    position={`relative`}
                >   
                    <Flex 
                        position={`absolute`}
                        width={{base:`100vw`, lg:`600px`}}
                        height={`0px`} 
                        alignItems={`end`}
                        top={`0px`}
                        background={`#AAA`}
                    >
                        <Image marginBottom={{base: `-100px`, lg:`-150px`}} src={`assets/advisor/${task[0]}`} width={`100%`} />
                    </Flex>
                    <TextBox>
                        <Box w={`100%`}>
                            <Box fontSize={{base:`18px`, lg:`36px`}}  textAlign={`center`}>{index[activeID].name}</Box>
                            <Box fontSize={{base:`14px`, lg:`20px`}}  textAlign={`center`}>{index[activeID].title}</Box>
                        </Box>
                    </TextBox>
                </Flex>
            </Flex>
        </Box>
    )
}