import { Box } from "@chakra-ui/react";
import { useApp } from "../App";
import { useEffect, useRef } from "react";


export default ({ setScrollTop }) => {
    const app = useApp();
    const ref = useRef();
    let arr = [];
    for (let i = 0; i < app.state.sectionLength; i++) {
        arr.push(i);
    }

    useEffect(() => {
        if(app.state.targetTop != null){
            ref.current.scrollTo({
                top: app.state.targetTop,
                behavior: "smooth"
            });
        }

        app.state.set((e) => {
            e.targetTop = null;
        })
    }, [app.state.targetTop]);


    return (
        <Box
            // pointerEvents={'none'}
            ref={ref}
            position={'absolute'}
            inset={'0px'}
            overflow = {'scroll'}
            zIndex={'-1'}
            onScroll = {(event) => {
                const scrollTop = event.currentTarget.scrollTop;
                // app.state.set((e) => {
                //     e.scrollTop = scrollTop;
                // });
                setScrollTop(scrollTop);
            }}
        >
            {arr.map((i) => {
                return (
                    <Box 
                        // pointerEvents={'none'}
                        key = {i}
                        position = {'relative'}
                        height = {`${app.state.sectionSize}00vh`}
                        width = {'100vw'}
                        color={'#AAA'}
                        zIndex={'-1'}
                    />
                )
            })}
        </Box>
    )
}