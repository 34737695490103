import { Box, Image } from "@chakra-ui/react";
import { useApp } from "../../App";
import ImagePreloader from "image-preloader";
import { useEffect } from "react";

export default () => {
    const start = 0;
    const end = 49;

    const app = useApp();
    let map = {
        [start]:{
            speed: 0,
        },
        [end]:{
            speed: 0,
            reach: 0,
        },
    };
    for(let i = start + 1; i < end; i++){
        map[i] = {
            speed: 0,
        }
    }
    const x = app.view.x(map);

    const move = app.view.range(start, end, 700).transform;

    const url = `assets/galaxy_bg.jpg`;
    useEffect(() => {
        ImagePreloader.simplePreload(url);
    }, []);

    if(x == null){
        return null;
    }else{
        
        //console.log(move);
        return (
            <Box 
                pointerEvents = {'none'}
                position = {'absolute'} 
                zIndex={0}
            >
                <Box
                    backgroundImage={`url(${url})`}
                    backgroundSize={`contain`}
                    backgroundRepeat={`repeat-y`}
                    backgroundPosition={`top`}
                    transform = {`translateY(${-move}vh)`}
                    width={`100vw`}
                    height={`3000vh`}
                />
            </Box>
        )
    }
}